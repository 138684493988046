export const FETCH_ITEMS_PENDING = 'FETCH_ITEMS_PENDING'
export const FETCH_ITEMS_FULFILLED = 'FETCH_ITEMS_FULFILLED'
export const FETCH_ITEMS_REJECTED = 'FETCH_ITEMS_REJECTED'
export const FETCH_USER_SELECTIONS_PENDING = 'FETCH_USER_SELECTIONS_PENDING'
export const FETCH_USER_SELECTIONS_FULFILLED = 'FETCH_USER_SELECTIONS_FULFILLED'
export const FETCH_USER_SELECTIONS_REJECTED = 'FETCH_USER_SELECTIONS_REJECTED'
export const ADD_SELECTION = 'ADD_SELECTION'
export const REMOVE_SELECTION = 'REMOVE_SELECTION'
export const USER_SELECTION_ISCHECKED_TOGGLE = 'USER_SELECTION_ISCHECKED_TOGGLE'
export const CREATE_USER_SELECTION_ARTICLE = 'CREATE_USER_SELECTION_ARTICLE'
export const GET_USER_ARTICLE = 'GET_USER_ARTICLE'
export const UPDATE_USER_ARTICLE = 'UPDATE_USER_ARTICLE'
export const ARTICLE_NOT_FOUND = 'ARTICLE_NOT_FOUND'
