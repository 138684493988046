export const FETCH_STEP_PENDING = 'FETCH_STEP_PENDING'
export const FETCH_STEP_FULFILLED = 'FETCH_STEP_FULFILLED'
export const FETCH_STEP_REJECTED = 'FETCH_STEP_REJECTED'

export const FETCH_ALL_INDUSTRIES_PENDING = 'FETCH_ALL_INDUSTRIES_PENDING'
export const FETCH_ALL_INDUSTRIES_FULFILLED = 'FETCH_ALL_INDUSTRIES_FULFILLED'
export const FETCH_ALL_INDUSTRIES_REJECTED = 'FETCH_ALL_INDUSTRIES_REJECTED'

export const FETCH_ALL_COMPANIES_PENDING = 'FETCH_ALL_COMPANIES_PENDING'
export const FETCH_ALL_COMPANIES_FULFILLED = 'FETCH_ALL_COMPANIES_FULFILLED'
export const FETCH_ALL_COMPANIES_REJECTED = 'FETCH_ALL_COMPANIES_REJECTED'

export const FETCH_ALL_INDUSTRY_PROBLEMS_PENDING =
  'FETCH_ALL_INDUSTRY_PROBLEMS_PENDING'
export const FETCH_ALL_INDUSTRY_PROBLEMS_FULFILLED =
  'FETCH_ALL_INDUSTRY_PROBLEMS_FULFILLED'
export const FETCH_ALL_INDUSTRY_PROBLEMS_REJECTED =
  'FETCH_ALL_INDUSTRY_PROBLEMS_REJECTED'

export const FETCH_ALL_EXPERIENCES_PENDING = 'FETCH_ALL_EXPERIENCES_PENDING'
export const FETCH_ALL_EXPERIENCES_FULFILLED = 'FETCH_ALL_EXPERIENCES_FULFILLED'
export const FETCH_ALL_EXPERIENCES_REJECTED = 'FETCH_ALL_EXPERIENCES_REJECTED'

export const FETCH_USER_PROBLEM_SOLUTION_PENDING =
  'FETCH_USER_PROBLEM_SOLUTION_PENDING'
export const FETCH_USER_PROBLEM_SOLUTION_FULFILLED =
  'FETCH_USER_PROBLEM_SOLUTION_FULFILLED'
export const FETCH_USER_PROBLEM_SOLUTION_REJECTED =
  'FETCH_USER_PROBLEM_SOLUTION_REJECTED'

export const FETCH_USER_EXPERIENCE_APPLICATION_PENDING =
  'FETCH_USER_EXPERIENCE_APPLICATION_PENDING'
export const FETCH_USER_EXPERIENCE_APPLICATION_FULFILLED =
  'FETCH_USER_EXPERIENCE_APPLICATION_FULFILLED'
export const FETCH_USER_EXPERIENCE_APPLICATION_REJECTED =
  'FETCH_USER_EXPERIENCE_APPLICATION_REJECTED'

export const HANDLE_EXPERIENCE_STATUS_PENDING =
  'HANDLE_EXPERIENCE_STATUS_PENDING'
export const HANDLE_EXPERIENCE_STATUS_FULFILLED =
  'HANDLE_EXPERIENCE_STATUS_FULFILLED'
export const HANDLE_EXPERIENCE_STATUS_REJECTED =
  'HANDLE_EXPERIENCE_STATUS_REJECTED'

export const HANDLE_INDUSTRY_PROBLEM_STATUS_PENDING =
  'HANDLE_INDUSTRY_PROBLEM_STATUS_PENDING'
export const HANDLE_INDUSTRY_PROBLEM_STATUS_FULFILLED =
  'HANDLE_INDUSTRY_PROBLEM_STATUS_FULFILLED'
export const HANDLE_INDUSTRY_PROBLEM_STATUS_REJECTED =
  'HANDLE_INDUSTRY_PROBLEM_STATUS_REJECTED'
