const EsLangs = {
  'general.edit': 'Editar',
  'general.view_all': 'Ver todo',
  'general.view_profile': 'Ver Perfil >>',
  'general.share': 'Compartir',
  'general.go_previous': 'Previa',
  'general.go_next': 'Próxima',
  'general.save': 'Guardar',
  'general.saving': 'Guardar...',
  'general.copy': 'Copied to clipboard',
  'general.copy_1': 'Copy',
  'general.message_success': 'Your message has been successfully sent.',
  'general.browse': 'Navega',
  'general.submit': 'Enviar',
  'general.start': '¡Empezar!',
  'general.login': 'Iniciar sesión',
  'general.readMore': 'Read more...',
  'general.verifyWithNova': 'Verify with Novae360',
  'general.loading': 'Loading...',

  'content.write_note': 'Escribe tu nota aquí',
  'content.my_notes': 'Mis notas',
  'content.my_chat': 'Mi chat',

  'navigation.dashboard': 'MI PANEL',
  'navigation.course': 'MI CURSO',
  'navigation.journal': 'MI DIARIO',
  'navigation.portfolio': 'MI PORTAFOLIO',
  'navigation.startup_live': 'MY STARTUP LIVE',
  'navigation.beyond_your_course': 'MÁS ALLÁ DE TU CURSO',
  'navigation.profile': 'Perfil',
  'navigation.contact_us': 'Contáctenos',
  'navigation.logout': 'Salir',
  'navigation.create_your_account': 'Ingrese a su cuenta',
  'navigation.login': 'Iniciar sesión',

  //Reset passsword
  'reset.reset_your_password': 'Restablecer Tu Contraseña',
  'reset.new_password': 'Contraseña Nueva',
  'reset.confirm_new_password': 'Confirma tu Contraseña Nueva',
  'reset.password_field_empty': 'Por favor rellena los campos de la contraseña',
  'reset.password_not_match':
    '¡La contraseña y la confirmación de la contraseña no coinciden!',
  'reset.password_conform_policy':
    'La contraseña debe tener al menos un número y caracteres en minúscula y mayúscula.',

  //Terms
  'terms.agreement_title': 'Acuerdo entre Usted y Startup Studio LLC',
  'terms.paragraph_1':
    'POR FAVOR LEA ESTE CONTRATO DE CONDICIONES DE USO (EL “TÉRMINOS“) Cuidadosamente. ACCESO O USO ESTE SITIO WEB O CUALQUIER OTROS SITIOS WEB DESTARTUP STUDIO LLC, (“Startup Studio LLC“) CON ENLACES A ESTE ACUERDO (colectivamente, EL“SITIO WEB“) DE CUALQUIER MÍA, INCLUIDO EL USO DE LOSSERVICIOS ENABITADO A LA WEB (LOS “SERVICIOS“)USUARIOS), haciendo clic en la casilla de verificación “Acepto“, o simplemente navegando por el sitio web, usted representa que ha leído, entendido y acepta estar vinculado por los términos. SI USTED NO ESTÁ DE ACUERDO EN QUEDAR OBLIGADO POR LOS TÉRMINOS, NO PUEDE ACCEDER O UTILIZAR ESTE SITIO WEB O LOS SERVICIOS.',
  'terms.paragraph_2':
    'ESTOS TÉRMINOS INCLUYEN UNA RENUNCIA A LA ACCIÓN COLECTIVA Y LA RENUNCIA A LOS JUICIOS CON JURADO, Y REQUIEREN UN ARBITRAJE VINCULANTE SOBRE UNA BASE INDIVIDUAL PARA RESOLVER DISPUTAS.',
  'terms.paragraph_3':
    'Los Servicios incluyen, entre otros, un sistema de gestión del aprendizaje que las entidades (“Entidades“) pueden suscribirse y proporcionar a sus instructores y alumnos (“Instructores“y “Estudiantes“) en relación con los cursos (cada uno, un“Curso“). Startup Studio LLC proporciona una cuenta que permite a un Usuario designado administrar los Servicios, administrar el acceso de Alumnos e Instructores a los Servicios y proporcionar soporte general en nombre de una Entidad. “Usuario“ se refiere a cualquier usuario de los Servicios, incluidos los Alumnos y los Instructores.',
  'terms.paragraph_4.1':
    '1. Uso de los Servicios y Propiedades de Startup Studio LLC. ',
  'terms.paragraph_4.2':
    '1. Uso de los Servicios y Propiedades de Startup Studio LLC. ',
  'paragraph_4.2':
    'El Sitio Web, los Servicios y la información y el contenido puestos a disposición (“Contenido“) por Startup Studio LLC en el Sitio Web y en los Servicios (colectivamente, las “Propiedades de Startup Studio LLC“) están protegidos por leyes de derechos de autor en todo el mundo . Sujeto a los Términos, Startup Studio LLC le otorga una licencia limitada para reproducir partes de Startup Studio LLC Properties con el único propósito de usar los Servicios para sus propósitos personales o, en el caso de que usted sea un Instructor, sus propósitos educativos.',
  'terms.paragraph_5.1': '1.1 Licencia de aplicación. ',
  'terms.paragraph_5.2':
    'Startup Studio LLC también puede proporcionar una aplicación móvil (la “Aplicación“) para su uso con los Servicios . Su uso de la Aplicación se rige por el Contrato de Licencia de Usuario Final que acompaña a la Aplicación en el mercado donde la descarga.',
  'terms.paragraph_6.1': '1.2 Ciertas restricciones.  ',
  'terms.paragraph_6.2':
    'os derechos que se le otorgan en los Términos están sujetos a las siguientes restricciones: (a) no podrá licenciar, vender, alquilar, arrendar, transferir, asignar, reproducir, distribuir, alojar o explotar comercialmente de alguna otra manera Startup Studio LLC Properties, (b) no utilizará técnicas de encuadre para incluir ninguna marca comercial, , u otras propiedades de Startup Studio LLC; (c) no utilizará ninguna metaetiquetas u otro “texto oculto“ utilizando el nombre o las marcas comerciales de Startup Studio LLC; (d) no modificará, traducirá, adaptará, fusionará, realizará trabajos derivados de, desensamblará, descompilará, compilará inversa o realizará ingeniería inversa de ninguna parte de Startup Studio. LLC LLC Properties excepto en la medida en que las restricciones anteriores estén expresamente prohibidas por la ley aplicable; (e) no deberá utilizar ningún software manual o automatizado, dispositivos u otros procesos (incluyendo, entre otros, arañas, robots, raspadores, rastreadores, avatares, herramientas de minería de datos, o similares) para “raspar“ o descargar datos de cualquier página web contenida en el Sitio Web; (f) excepto como se indica expresamente en el presente documento, ninguna parte de Startup Studio LLC Properties puede ser copiada, reproducida, distribuida, republicada, descargada, mostrada o transmitida en cualquier forma o por cualquier medio; y (g) usted no debe eliminar o destruir cualquier aviso de copyright u otras marcas de propiedad contenidas en o en Startup Studio LLC LLC Properties. Cualquier versión futura, actualización u otra adición a Startup Studio LLC Properties estará sujeta a los Términos. Startup Studio LLC, sus proveedores y proveedores de servicios se reservan todos los derechos no concedidos en los Términos. Cualquier uso no autorizado de Startup Studio LLC Properties termina las licencias otorgadas por Startup Studio LLC de conformidad con los Términos',
  'terms.paragraph_7.1': '2. Registration. ',
  'terms.paragraph_7.2':
    'Para acceder a ciertas características de Startup Studio LLC Properties, debe crear una cuenta (“Cuenta“). Algunas funciones solo están disponibles para las cuentas de instructor.',
  'terms.paragraph_8.1': '2.1 Cuentas de instructor. ',
  'terms.paragraph_8.2':
    'Si usted es un Instructor, Startup Studio LLC le proporcionará acceso a una Cuenta de Instructor, siempre que (1) proporcione cualquier información necesaria sobre usted y/o su escuela al registrar la Cuenta (“Datos de Registro“) y (2) acepte los Términos .',
  'terms.paragraph_9.1': '2.2 Cuentas de alumno. ',
  'terms.paragraph_9.2':
    'Para crear una Cuenta de Aprendiz, debe (1) tener acceso a un código de invitación de cuenta por parte de la Entidad, (2) proporcionarnos los Datos de Registro solicitados en los formularios de registro y pago, y (3) aceptar las Condiciones.',
  'terms.paragraph_10.1': '2.3 Datos de registro. ',
  'terms.paragraph_10.2':
    'Al registrarse en los Servicios, usted acepta (1) proporcionar Datos de Registro verdaderos, actuales y completos; y (2) mantener y actualizar rápidamente los Datos de Registro para mantenerlos verdaderos, actualizados y completos . Usted es responsable de todas las actividades que se realicen bajo su Cuenta. Usted no puede compartir su Cuenta o contraseña con nadie, y usted acepta notificar inmediatamente a Startup Studio LLC de cualquier uso no autorizado de su contraseña o cualquier otra violación de la seguridad. Usted acepta no crear o acceder a una Cuenta usando una identidad o información falsa, o en nombre de alguien que no sea usted mismo. Usted acepta que no tendrá más de una Cuenta en un momento dado. Usted acepta no crear una Cuenta o usar las Propiedades de Startup Studio LLC si ha sido eliminado previamente por Startup Studio LLC, o si ha sido previamente excluido de cualquiera de las Propiedades de Startup Studio LLC.',
  'terms.paragraph_11.1': '2.4 Servicios de redes sociales. ',
  'terms.paragraph_11.2':
    'Podemos permitirle iniciar sesión en los Servicios con sus credenciales de inicio de sesión desde ciertos sitios de redes sociales (por ejemplo, Facebook) (“SNS“) . Si inicia sesión o asocia de otro modo su Cuenta con sus credenciales de inicio de sesión de dicho SNS, podemos recibir información sobre usted de dicho SNS, de acuerdo con los términos y condiciones (por ejemplo, términos de uso y política de privacidad) del SNS (“Términos de SNS“). Si decide compartir su información con estos SNS, compartiremos información con ellos de acuerdo con su elección. Los Términos de SNS de dicho SNS se aplicarán a la información que les revelemos.',
  'terms.paragraph_12.1': '2.5 Equipo y software necesarios. ',
  'terms.paragraph_12.2':
    'Debe proporcionar todo el equipo y el software necesarios para conectarse a Startup Studio LLC Properties, incluyendo pero no limitado a, un dispositivo móvil que sea adecuado para conectarse y usar la Aplicación . Usted es el único responsable de cualquier tarifa, incluida la conexión a Internet o las tarifas móviles, que incurra al acceder a Startup Studio LLC Properties.',
  'terms.paragraph_13.1': '3. Condiciones de pago. ',
  'terms.paragraph_13.2':
    'Todos los Cursos ofrecidos como parte de los Servicios están disponibles para los Alumnos a cambio de una tarifa . Usted puede comprar dichos Cursos siguiendo las instrucciones en el Sitio. Si compra un Curso, acepta pagar la tarifa vigente del Curso que aparece en el Sitio (“Tarifas del Curso“) y Startup Studio LLC facturará la tarjeta de crédito o la cuenta de PayPal que envíe al comprar el Curso. Todos los pagos son reembolsables de acuerdo con la política de reembolsos estándar de Startup Studio LLC, si los hubiera.. Usted autoriza a Startup Studio LLC a facturar en su tarjeta de crédito o cuenta de PayPal, según corresponda, las tarifas del curso como se describe anteriormente. Las tarifas del curso no incluyen todos los impuestos, gravámenes o impuestos impuestos por las autoridades tributarias, y usted es responsable del pago de todos esos impuestos, gravámenes o derechos. Si no se puede cargar ninguna Tarifa del curso a su tarjeta de crédito o cuenta de PayPal por cualquier motivo, Startup Studio LLC puede proporcionarle, por correo electrónico, un aviso de dicho impago y un enlace para que actualice su información de pago. Si dicho impago no se soluciona dentro de los siete (7) días posteriores a la recepción de dicho aviso de impago, Startup Studio LLC podrá cancelar su acceso al Curso',
  'terms.paragraph_14': '4 Responsabilidad por el contenido.',
  'terms.paragraph_15.1': '4.1 Tipos de contenido. ',
  'terms.paragraph_15.2':
    'Usted reconoce que todo el Contenido, incluidas las Propiedades de Startup Studio LLC, es responsabilidad exclusiva de la parte de la que se originó dicho Contenido . Esto significa que usted, y no Startup Studio LLC, es totalmente responsable de todo el Contenido que cargue, publique, envíe por correo electrónico, transmita o de otro modo ponga a disposición (“Hacer disponible“) a través de las Propiedades de Startup Studio LLC (“Su contenido“). Otros usuarios de Startup Studio LLC Properties, y no Startup Studio LLC, son igualmente responsables de todo el contenido que otros usuarios ponen a disposición a través de las propiedades de Startup Studio LLC (“Contenido de usuario“).',
  'terms.paragraph_16.1':
    '4.2 No hay obligación de preseleccionar el contenido. ',
  'terms.paragraph_16.2':
    'Usted reconoce que Startup Studio LLC no tiene ninguna obligación de preseleccionar Contenido (incluyendo, pero no limitado a, Su Contenido y Contenido de Usuario), aunque Startup Studio LLC se reserva el derecho, a su entera discreción, de preseleccionar, rechazar o eliminar cualquier Contenido que (1) viole cualquier ley o reglamento, (2 ) viola estos Términos, incluido el Código de Conducta del Usuario establecido en la Sección 5.4 del presente Acuerdo, y/o (3) crea responsabilidad por Startup Studio LLC.',
  'terms.paragraph_17': '5. Propiedad.',
  'terms.paragraph_18.1': '5.1 Propiedades de Startup Studio LLC. ',
  'terms.paragraph_18.2':
    'Excepto con respecto a Su Contenido y Contenido de Usuario, usted acepta que Startup Studio LLC y sus proveedores poseen todos los derechos, títulos e intereses en Startup Studio LLC Properties . El nombre de Startup Studio LLC y otros gráficos relacionados, logotipos, marcas de servicio y nombres comerciales utilizados en o en conexión con Startup Studio LLC Properties son marcas comerciales de Startup Studio LLC y no pueden utilizarse sin permiso en relación con productos o servicios de terceros. Otras marcas comerciales, marcas de servicio y nombres comerciales que pueden aparecer en o en Startup Studio LLC Properties son propiedad de sus respectivos propietarios.',
  'terms.paragraph_19.1': '5.2 Su contenido. ',
  'terms.paragraph_19.2':
    'Su Contenido seguirá siendo en todo momento su propiedad exclusiva y usted acepta que no tiene derecho, título o interés sobre o sobre cualquier otro Contenido que aparezca en o en las Propiedades de Startup Studio LLC . Usted declara que tiene todo el derecho, el poder y la autoridad necesarios para publicar Su Contenido en Startup Studio LLC Properties. Usted se compromete a permitir a Startup Studio LLC y sus contratistas aplicables alojar, reproducir, transmitir, modificar, mostrar y utilizar de otra manera Su Contenido (en su totalidad o en parte) según sea razonablemente necesario para proporcionarle los Servicios, y de acuerdo con el acuerdo de Startup Studio LLC con su Entidad, si aplicable.',
  'terms.paragraph_20.1': '5.3 Su cuenta. ',
  'terms.paragraph_20.2':
    'Sin perjuicio de cualquier cosa contenida en el presente documento en contrario, al enviar Su Contenido a cualquier foro, comentario o cualquier otra área en o en Startup Studio LLC Properties, usted permite expresamente que Startup Studio LLC lo identifique por su nombre de usuario como colaborador de Su Contenido en cualquier publicación en cualquier forma, medios o tecnología ahora conocida o desarrollada posteriormente en relación con Su Contenido.',
  'terms.paragraph_21.1': '5.4 Código de conducta del usuario. ',
  'terms.paragraph_21.2':
    'Como condición de uso, usted acepta no usar Startup Studio LLC Properties para ningún propósito prohibido por los Términos o por la ley aplicable . No publique, ni permita que otros publiquen, contenido en Startup Studio LLC Properties o en su perfil que (i) fomente actividades ilegales, sean fraudulentas o ilegales; (ii) insulte, difame, acosa o amenace a otros; (iii) viole los derechos de propiedad intelectual o de privacidad de terceros; (iv) contiene material obsceno, vulgar, pornográfico o calumnioso; v) daña o suplanta a otros, incluidos otros Usuarios; o vi) anuncia o vende un producto o servicio. No reproduzca contenido de su Curso u otros Alumnos a menos que lo permitan los términos expresos de derechos de autor establecidos por el Instructor (por ejemplo, Creative Commons). No comparta las soluciones a las asignaciones con otros a menos que el Instructor lo autorice expresamente. No envíe el trabajo de otros como su propio trabajo. Respetar la privacidad de otros Usuarios. Respetar la diversidad de opiniones y culturas que serán presentadas por otros Usuarios. No intente ni participe en ningún acto potencialmente dañino dirigido contra las propiedades de Startup Studio LLC, incluyendo, entre otros, violar o intentar violar cualquier característica de seguridad de Startup Studio LLC Properties, introducir virus, gusanos o código dañino similar en Startup Studio LLC LC Propiedades, o interferir o intentar interferir con el uso de Startup Studio LLC Propiedades por cualquier otro usuario, host o red, incluso por medio de sobrecarga, “inundación“, “spam“, “bombardeo de correo“ o “estrellarse“ Propiedades de Startup Studio LLC. Si cree que alguien ha violado este código de conducta, comience notificando al Instructor del Curso. Si el Instructor no aborda el problema a su satisfacción, póngase en contacto con help@joinstartupstudio.com con sus dudas.',
  'terms.paragraph_22.1': '5.5 Comentarios. ',
  'terms.paragraph_22.2':
    'Usted acepta que el envío de cualquier idea, sugerencia, documento y/o propuesta a Startup Studio LLC a través de sus sugerencias, comentarios, wiki, foro o páginas similares (“Comentarios“) es bajo su propio riesgo y que Startup Studio LLC no tiene obligaciones (incluyendo, sin limitación, obligaciones de confidencialidad) con respecto a dichos comentarios. Usted declara y garantiza que tiene todos los derechos necesarios para enviar los comentarios. Por la presente, otorga a Startup Studio LLC un derecho y licencia totalmente pagados, libres de regalías, perpetuos, irrevocables, mundiales, no exclusivos y totalmente sublicenciables para usar, reproducir, ejecutar, mostrar, distribuir, adaptar, modificar, reformatear, crear obras derivadas de, y de otro modo comercial o no comercialmente explotar de cualquier manera, todos y cada uno de los Comentarios, y sublicenciar los derechos anteriores, en relación con el funcionamiento y mantenimiento de Startup Studio LLC Properties.',
  'terms.paragraph_23.1': '6. Investigaciones. ',
  'terms.paragraph_23.2':
    'Startup Studio LLC puede, pero no está obligado a, supervisar o revisar las propiedades y el contenido de Startup Studio LLC en cualquier momento . Sin limitar lo anterior, Startup Studio LLC tendrá el derecho, a su entera discreción, de eliminar cualquiera de Su Contenido por cualquier motivo (o ninguna razón), incluso si dicho Contenido viola los Términos o cualquier ley aplicable. Aunque Startup Studio LLC generalmente no supervisa la actividad del usuario que se produce en relación con Startup Studio LLC Properties, si Startup Studio LLC tiene conocimiento de cualquier posible violación por usted de cualquier disposición de los Términos, Startup Studio LLC se reserva el derecho de investigar tales violaciones, y Startup Studio LLC Studio LLC puede, a su entera discreción, rescindir su licencia para usar las Propiedades de Startup Studio LLC, o cambiar, alterar o eliminar Su Contenido, en su totalidad o en parte.',
  'terms.paragraph_24': '7. Interactions with Other Users.',
  'terms.paragraph_25.1': '7.1 Responsabilidad del usuario. ',
  'terms.paragraph_25.2':
    'Usted es el único responsable de sus interacciones con otros Usuarios de los Servicios y cualquier otra parte con la que interactúe a través de los Servicios; sin embargo, Startup Studio LLC se reserva el derecho, pero no tiene obligación, de interceder en disputas entre Usuarios . Usted acepta que Startup Studio LLC no será responsable de ninguna responsabilidad incurrida como resultado de dicha interacción.',
  'terms.paragraph_26.1': '7.2 Contenido proporcionado por otros usuarios. ',
  'terms.paragraph_26.2':
    'Las propiedades de Startup Studio LLC pueden contener Contenido de Usuario proporcionado por otros Usuarios . Startup Studio LLC no es responsable ni controla el Contenido del Usuario. Startup Studio LLC no tiene la obligación de revisar o supervisar, y no aprueba, aprueba o hace ninguna declaración o garantía con respecto al Contenido del Usuario. Usted utiliza todo el Contenido del Usuario e interactúa con otros Usuarios bajo su propio riesgo.',
  'terms.paragraph_27': '8. Sitios web de terceros.',
  'terms.paragraph_28.1': '8.1 Sitios web de terceros. ',
  'terms.paragraph_28.2':
    'as propiedades de Startup Studio LLC pueden contener enlaces a sitios web de terceros (“Sitios web de terceros“) . Al hacer clic en un enlace a un Sitio Web de Terceros, no le avisaremos de que ha abandonado las Propiedades de Startup Studio LLC y está sujeto a términos y condiciones o políticas de privacidad independientes. Tales sitios web de terceros no están bajo el control de Startup Studio LLC. Startup Studio LLC no es responsable de ningún Sitio Web de Terceros y no revisa, aprueba, monitorea, avala, garantiza ni hace ninguna declaración con respecto a los Sitios Web de Terceros, o sus productos o servicios. Usted utiliza todos los enlaces en sitios web de terceros bajo su propio riesgo. Debe revisar los términos y políticas aplicables, incluidas las prácticas de privacidad y recopilación de datos, de cualquier Sitio Web de Terceros, y debe realizar cualquier investigación que considere necesaria o apropiada antes de proceder con cualquier transacción con terceros.',
  'terms.paragraph_29.1': '8.2 Tiendas de aplicaciones. ',
  'terms.paragraph_29.2':
    'Usted reconoce y acepta que la disponibilidad de la Aplicación y los Servicios depende del tercero del que recibió la Aplicación, por ejemplo, las tiendas de aplicaciones de Apple o Android .',
  'terms.paragraph_30.1': '9. Indemnización. ',
  'terms.paragraph_30.2':
    'Usted se compromete a indemnizar, defender y mantener indemne a Startup Studio LLC, sus padres, subsidiarias, afiliados, funcionarios, empleados, agentes, socios y licenciantes (colectivamente las “Partes de Startup Studio LLC“) de cualquier pérdida, costo, responsabilidad y gasto (incluyendo honorarios razonables de abogados ) relacionados con o que surjan de: (a) Su Contenido; (b) su uso de, o incapacidad de usar, Startup Studio LLC Properties; (c) su violación de los Términos; (d) su violación de cualquier derecho de otra parte, incluido cualquier Usuario; o (e) su violación de cualquier ley, norma o reglamento aplicable. Startup Studio LLC se reserva el derecho, a su propio costo, de asumir la defensa y el control exclusivos de cualquier asunto sujeto a indemnización por su parte, en cuyo caso cooperará plenamente con Startup Studio LLC en la afirmación de cualquier defensa disponible. Usted acepta que las disposiciones de esta sección sobrevivirán a cualquier terminación de su Cuenta, los Términos o su acceso a Startup Studio LLC Properties.',
  'terms.paragraph_31.1': '10. Descargo de responsabilidad de garantías. ',
  'terms.paragraph_31.2':
    'USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE, EN LA MEDIDA PERMITIDA POR LA LEY APLICABLE, SU USO DE LAS PROPIEDADES DE STARTUP STUDIO LLC ES BAJO SU PROPIO RIESGO, Y LAS PROPIEDADES DE STARTUP STUDIO LLC SE PROPORCIONAN “TAL CUAL“ Y “SEGÚN DISPONIBILIDAD“, CON TODAS LAS FALLAS STARTUP STUDIO LLC PARTES RENUNCIAN EXPRESAMENTE A TODAS LAS GARANTÍAS, REPRESENTACIONES Y CONDICIONES DE CUALQUIER TIPO, YA SEAN EXPRESAS O IMPLÍCITAS, INCLUYENDO, PERO NO LIMITADO A, LAS GARANTÍAS IMPLÍCITAS O CONDICIONES DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR Y NO INFRACCIÓN. STARTUP STUDIO LLC PARTES NO HACEN NINGUNA GARANTÍA, REPRESENTACIÓN O CONDICIÓN DE QUE: (1) PROPIEDADES DE STARTUP STUDIO LLC SATISFAGAN SUS REQUISITOS; (2) SU USO DE LAS PROPIEDADES DE STARTUP STUDIO LLC SERÁ ININTERRUMPIDO, OPORTUNO, SEGURO O LIBRE DE ERRORES; (3) LOS RESULTADOS QUE SE PUEDEN OBTENER DEL USO DE STARTUP STUDIO LLC SERÁN PRECISAS O CONFIABLES; O (4) CUALQUIER ERROR EN LAS PROPIEDADES DE STARTUP STUDIO LLC SERÁ CORREGIDO. CUALQUIER CONTENIDO DESCARGADO DESDE O ACCEDIDO DE OTRA MANERA A TRAVÉS DE LAS PROPIEDADES DE STARTUP STUDIO LLC SE ACCEDE BAJO SU PROPIO RIESGO, Y USTED SERÁ EL ÚNICO RESPONSABLE DE CUALQUIER DAÑO A SU PROPIEDAD O PERSONA, INCLUYENDO, PERO NO LIMITADO A, SU SISTEMA INFORMÁTICO Y CUALQUIER DISPOSITIVO QUE USE PARA ACCEDER A LAS PROPIEDADES DE STARTUP STUDIO LLC. O CUALQUIER OTRA PÉRDIDA QUE RESULTE DEL ACCESO A DICHO CONTENIDO. LOS SERVICIOS PUEDEN ESTAR SUJETOS A RETRASOS, CANCELACIONES Y OTRAS INTERRUPCIONES. STARTUP STUDIO LLC NO HACE NINGUNA GARANTÍA, REPRESENTACIÓN O CONDICIÓN CON RESPECTO A LOS SERVICIOS, INCLUYENDO PERO NO LIMITADO A, LA CALIDAD, EFECTIVIDAD, REPUTACIÓN Y OTRAS CARACTERÍSTICAS DE LOS SERVICIOS. NINGÚN CONSEJO O INFORMACIÓN, YA SEA ORAL O ESCRITA, OBTENIDA DE STARTUP STUDIO LLC O A TRAVÉS DE STARTUP STUDIO LLC PROPIEDADES CREARÁ NINGUNA GARANTÍA NO HECHA EXPRESAMENTE EN EL PRESENTE DOCUMENTO.',
  'terms.paragraph_32': '11. Limitación de responsabilidad.',
  'terms.paragraph_33.1': '11.1 Descargo de responsabilidad de ciertos daños. ',
  'terms.paragraph_33.2':
    'Usted entiende y acepta que en ningún caso las partes de Startup Studio LLC serán responsables de cualquier daño indirecto, incidental, especial, ejemplar o consecuente que surja de o en conexión con las propiedades de Startup Studio LLC, incluyendo, sin limitación, cualquier daño resultante de la pérdida de uso, datos, o BENEFICIOS, YA SEA STARTUP STUDIO LLC HA SIDO ADVERTIDO DE LA POSIBILIDAD DE TALES DAÑOS, O POR CUALQUIER DAÑO POR LESIONES PERSONALES O CORPORALES O ANGUSTIA EMOCIONAL QUE SURJA DE O EN RELACIÓN CON LOS TÉRMINOS, O DE CUALQUIER COMUNICACIÓN, INTERACCIÓN O REUNIÓN CON OTROS USUARIOS DE STARTUP STUDIO LLC PROPIEDADES, EN CUALQUIER TEORÍA DE LA RESPONSABILIDAD, RESULTANTE DE: (1) EL USO O LA IMPOSIBILIDAD DE UTILIZAR LAS PROPIEDADES DE STUDIO DE STARTUP LLC; (2) EL COSTO DE LA ADQUISICIÓN DE BIENES O SERVICIOS SUSTITUTIVOS RESULTANTES DE CUALQUIER PRODUCTO, DATOS, INFORMACIÓN O SERVICIOS COMPRADOS U OBTENIDOS O MENSAJES RECIBIDOS PARA TRANSACCIONES REALIZADAS EN A TRAVÉS DE LAS PROPIEDADES DE STARTUP STUDIO LLC; (3) ACCESO NO AUTORIZADO O ALTERACIÓN DE SUS TRANSMISIONES O DATOS; (4) DECLARACIONES O CONDUCTA DE CUALQUIER TERCERO EN LAS PROPIEDADES DE STARTUP STUDIO LLC; O (5) CUALQUIER OTRO ASUNTO RELACIONADO CON LAS PROPIEDADES DE STARTUP STUDIO LLC, YA SEA BASADO EN GARANTÍA, COPYRIGHT, CONTRATO, AGRAVIO (INCLUIDA LA NEGLIGENCIA), LA RESPONSABILIDAD DEL PRODUCTO O CUALQUIER OTRA TEORÍA JURÍDICA.',
  'terms.paragraph_34.1': '11.2 Límite de responsabilidad. ',
  'terms.paragraph_34.2':
    'Bajo ninguna circunstancia STARTUP STUDIO LLC PARTES serán responsables ante usted por más de cincuenta dólares ($50 USD) .',
  'terms.paragraph_35.1': '11.3 Contenido del usuario. ',
  'terms.paragraph_35.2':
    'Las PARTES STARTUP STUDIO LLC no asumen responsabilidad alguna por la puntualidad, eliminación, entrega errónea o falta de almacenamiento de cualquier contenido (incluyendo, pero no limitado a, su contenido y contenido del usuario), las comunicaciones del usuario o la configuración de personalización .',
  'terms.paragraph_36.1':
    '11.4 Ninguna responsabilidad por la conducta de terceros u otros usuarios. ',
  'terms.paragraph_36.2':
    'Usted reconoce y acepta que las PARTES STARTUP STUDIO LLC no son responsables, y usted acepta no tratar de mantener RESPONSABLE a las PARTES STARTUP STUDIO LLC, por la conducta de TERCEROS, incluidos los operadores de sitios externos, y que el riesgo de lesiones de tales terceros recae enteramente en usted . USTED ES EL ÚNICO RESPONSABLE DE TODAS SUS COMUNICACIONES E INTERACCIONES CON OTROS USUARIOS DE LAS PROPIEDADES DE STARTUP STUDIO LLC. ENTIENDE QUE STARTUP STUDIO LLC NO HACE NINGÚN INTENTO DE VERIFICAR LAS DECLARACIONES DE LOS USUARIOS DE LAS PROPIEDADES DE STARTUP STUDIO LLC.',
  'terms.paragraph_37.1': '11.5 Bases de la negociación. ',
  'terms.paragraph_37.2':
    'Las limitaciones de los daños expuestos anteriormente son elementos fundamentales de la base de la negociación entre Startup Studio LLC y usted .',
  'terms.paragraph_38.1':
    '12. Procedimiento para la presentación de reclamaciones por infracción de derechos de autor. ',
  'terms.paragraph_38.2':
    'La política de Startup Studio LLC es rescindir los privilegios de membresía de cualquier Usuario que infrinja repetidamente los derechos de autor tras una notificación rápida a Startup Studio LLC por el propietario de los derechos de autor o el agente legal del propietario de los derechos de autor . Sin limitar lo anterior, si usted cree que su trabajo ha sido copiado y publicado en Startup Studio LLC Properties de una manera que constituya una infracción de derechos de autor, por favor proporcione a nuestro Agente de Derechos de Autor la siguiente información: (1) una firma electrónica o física de la persona autorizada para actuar sobre nombre del propietario de los derechos de autor; (2) una descripción de la obra protegida por derechos de autor que usted afirma ha sido infringido; (3) una descripción de la ubicación en Startup Studio LLC Propiedades del material que usted afirma que está infringiendo; (4) su dirección, número de teléfono y dirección de correo electrónico; (5) un escrito declaración por usted de que usted cree de buena fe que el uso en disputa no está autorizado por el propietario de los derechos de autor, su agente o la ley; (6) una declaración por usted, hecha bajo pena de perjurio, de que la información anterior en su aviso es exacta y que usted es el propietario de los derechos de autor o autorizado para actuar sobre la nombre del propietario de los derechos de autor. La información de contacto del Agente de Derechos de Autor de Startup Studio LLC para la notificación de reclamaciones de infracción de derechos de autor es la siguiente: Beusse Wolter Sanks & Maire PLLC, 390 North Orange Ave. Suite 2500, Orlando, FL 32801; legal@joinstartupstudio.com.',
  'terms.paragraph_39': '13. Plazo y terminación.',
  'terms.paragraph_40.1': '13.1 Término. ',
  'terms.paragraph_40.2':
    'Los Términos comienzan en la fecha en que los acepta (como se describe en el preámbulo anterior) y permanecen en pleno vigor y efecto mientras usa Startup Studio LLC Properties, a menos que se termine antes de acuerdo con los Términos .',
  'terms.paragraph_41.1':
    '13.2 Terminación de Servicios por Startup Studio LLC. ',
  'terms.paragraph_41.2':
    'Si ha incumplido alguna disposición de los Términos, o si Startup Studio LLC está obligado a hacerlo por ley (por ejemplo, cuando la provisión del Sitio Web, la Aplicación o los Servicios es, o se vuelve, ilegal), Startup Studio LLC tiene el derecho de suspender o cancelar cualquier Servicio que se le proporcione o de eliminar cualquier de Su Contenido. Usted acepta que todas las terminaciones por causa se realizarán a la entera discreción de Startup Studio LLC y que Startup Studio LLC no será responsable ante usted o cualquier tercero por cualquier terminación de su Cuenta. La terminación de cualquier Servicio incluye la eliminación del acceso a dicho Servicio y la prohibición del uso posterior del Servicio. La terminación de todos los Servicios también puede incluir la eliminación de su contraseña y toda la información relacionada, archivos y Contenido asociados con o dentro de su Cuenta (o cualquier parte de la misma), incluido Su Contenido. Tras la terminación de cualquier Servicio, su derecho a usar dicho Servicio terminará automáticamente de inmediato. Startup Studio LLC no tendrá ninguna responsabilidad ante usted por cualquier suspensión o terminación, incluida la eliminación de Su Contenido. Todas las disposiciones de los Términos que por su naturaleza deben sobrevivir, sobrevivirán a la terminación de los Servicios, incluyendo, sin limitación, disposiciones de propiedad, exenciones de garantía y limitación de responsabilidad.',
  'terms.paragraph_42': '14. Disposiciones generales.',
  'terms.paragraph_43.1': '14.1 Comunicaciones electrónicas. ',
  'terms.paragraph_43.2':
    'Las comunicaciones entre usted y Startup Studio LLC utilizan medios electrónicos, ya sea que visite Startup Studio LLC Properties o envíe correos electrónicos de Startup Studio LLC, o si Startup Studio LLC publica avisos en Startup Studio LLC Properties o se comunica con usted por correo electrónico . Para fines contractuales, usted (1) consiente en recibir comunicaciones de Startup Studio LLC en forma electrónica; y (2) acepta que todos los términos y condiciones, acuerdos, avisos, divulgaciones y otras comunicaciones que Startup Studio LLC le proporcione electrónicamente satisfacen cualquier requisito legal que tales las comunicaciones satisfarían si se hiciera por escrito. Lo anterior no afecta a los derechos legales que usted pueda tener.',
  'terms.paragraph_44.1': '14.2 Liberación. ',
  'terms.paragraph_44.2':
    'Usted libera a Startup Studio LLC Parties y sus sucesores de reclamaciones, demandas, cualquier y todas las pérdidas, daños, derechos y acciones de cualquier tipo, incluyendo lesiones personales, muerte y daños a la propiedad, que esté directa o indirectamente relacionada con o surja de cualquier interacción con o conducta de otros Usuarios del Sitio Web o sitios web de terceros de cualquier tipo que surjan en relación con o como resultado de los Términos o su uso de Startup Studio LLC Properties. Si usted es residente de California, por la presente renuncia a la Sección 1542 del Código Civil de California, que establece: “Una liberación general no se extiende a las reclamaciones que el acreedor no sabe o sospecha que existen a su favor en el momento de ejecutar la liberación, lo que, si es conocido por él, debe haber afectado materialmente su acuerdo con el deudor “.',
  'terms.paragraph_45.1': '14.3 Asignación. ',
  'terms.paragraph_45.2':
    'Los Términos, así como sus derechos y obligaciones en virtud del presente documento, no pueden ser cedidos, subcontratados, delegados o transferidos de otro modo sin el consentimiento previo por escrito de Startup Studio LLC, y cualquier intento de cesión, subcontrato, delegación o transferencia en violación de lo anterior será nulo y sin efecto . Startup Studio LLC puede ceder sus derechos y obligaciones bajo estos Términos a un tercero sin su consentimiento.',
  'terms.paragraph_46.1': '14.4 Fuerza Mayor. ',
  'terms.paragraph_46.2':
    'Startup Studio LLC no será responsable de ninguna demora o falta de ejecución resultante de causas fuera de su control razonable, incluyendo, pero no limitado a, actos de Dios, guerra, terrorismo, disturbios, embargos, actos de autoridades civiles o militares, incendios, inundaciones, accidentes, huelgas o escasez de transporte instalaciones, combustible, energía, mano de obra o materiales.',
  'terms.paragraph_47.1': '14.5 Cumplimiento. ',
  'terms.paragraph_47.2':
    'Si cree que Startup Studio LLC no se ha adherido a los Términos, póngase en contacto con Startup Studio LLC enviándonos un correo electrónico a legal@joinstartupstudio.com . Haremos todo lo posible para abordar sus preocupaciones. Si considera que su queja ha sido resuelta de manera incompleta, le invitamos a que nos lo haga saber para una investigación posterior.',
  'terms.paragraph_48':
    '14.6 Acuerdo de Arbitraje; Renuncia de Clase; Renuncia del Juicio por Jurado.',
  'terms.paragraph_49':
    'Lea atentamente este Acuerdo de Arbitraje . Es parte de su contrato con Startup Studio LLC y afecta sus derechos. Contiene procedimientos para el arbitraje obligatorio obligatorio y una renuncia a la acción colectiva. a',
  'terms.paragraph_50':
    '(a) Aplicabilidad del Acuerdo de Arbitraje. Todas las reclamaciones y disputas (excluyendo las reclamaciones por medidas cautelares u otras medidas equitativas como se establece a continuación) en relación con los Términos o el uso de cualquier producto o servicio proporcionado por Startup Studio LLC que no se puedan resolver informalmente o en un tribunal de reclamos menores se resolverán mediante arbitraje vinculante en un de acuerdo con los términos del presente Acuerdo de Arbitraje. Este Acuerdo de Arbitraje se aplica a usted y a Startup Studio LLC, y a cualquier subsidiaria, afiliada, agente, empleados, predecesores en interés, sucesores y cesionarios, así como a todos los usuarios autorizados o no autorizados o beneficiarios de los servicios o bienes proporcionados bajo los Términos.',
  'terms.paragraph_51':
    '(b) Requisitos de notificación y solución informal de controversias. Antes de que cualquiera de las partes pueda recurrir al arbitraje, la parte debe enviar primero a la otra parte una Notificación escrita de Disputa (“Notificación“) en la que se describa la naturaleza y la base de la reclamación o controversia, y la medida cautelar solicitada. Se debe enviar un aviso a Startup Studio LLC a: Attn: Shuffield Lowman, 1000 Legion Place, Suite 1700, Orlando, FL 32801. Después de recibir el Aviso, usted y Startup Studio LLC pueden intentar resolver la reclamación o disputa de manera informal. Si usted y Startup Studio LLC no resuelven la reclamación o disputa dentro de los 30 días siguientes a la recepción del Aviso, cualquiera de las partes puede iniciar un procedimiento de arbitraje. El monto de cualquier oferta de transacción hecha por cualquiera de las partes no podrá revelarse al árbitro hasta después de que el árbitro haya determinado el monto del laudo, en su caso, al que cualquiera de las partes tiene derecho.',
  'terms.paragraph_52':
    '(c) Reglamento de Arbitraje. El arbitraje se iniciará a través de la Asociación Americana de Arbitraje (“AAA“), un proveedor de solución alternativa de controversias establecido (“Proveedor ADR“) que ofrece arbitraje como se establece en esta sección. Si AAA no está disponible para arbitrar, las partes acordarán seleccionar un proveedor alternativo de ADR. Las reglas del Proveedor de ADR regirán todos los aspectos de este arbitraje, incluyendo, entre otros, el método para iniciar y/o exigir arbitraje, excepto en la medida en que tales reglas estén en conflicto con los Términos. Las Reglas de Arbitraje de Consumidores de la AAA que rigen el arbitraje están disponibles en línea en www.adr.org o llamando a la AAA al 1-800-778-7879. El arbitraje será conducido por un árbitro único y neutral. Cualquier reclamación o disputa en la que el monto total del laudo solicitado sea inferior a Diez Mil Dólares de los Estados Unidos ($10.000,00 USD) puede resolverse mediante arbitraje vinculante no basado en la comparecencia, a elección de la parte que solicite la reparación. Para reclamaciones o disputas en las que el monto total del laudo solicitado sea de Diez Mil Dólares Estadounidenses ($10.000,00 USD) o más, el derecho a una audiencia será determinado por el Reglamento de Arbitraje. Cualquier audiencia se llevará a cabo en un lugar situado a 100 millas de su residencia, a menos que usted resida fuera de los Estados Unidos, y a menos que las partes acuerden lo contrario. Cualquier fallo sobre el laudo dictado por el árbitro podrá ser dictado en cualquier tribunal de jurisdicción competente. Cada parte cargará con sus propios gastos (incluidos los honorarios de abogados) y los desembolsos derivados del arbitraje, y pagará una parte igual de los honorarios y costos del Proveedor de ADR.',
  'terms.paragraph_53':
    '(d) Reglas adicionales para el arbitraje no basado en comparecencia: Si se elige un arbitraje sin comparecencia, el arbitraje se llevará a cabo por teléfono, en línea y/o basado únicamente en presentaciones escritas; la forma específica será elegida por la parte que inicie el arbitraje. El arbitraje no implicará comparecencia personal de las partes o los testigos a menos que las partes acuerden otra cosa. e',
  'terms.paragraph_54':
    '(e) Plazos. Si usted o Startup Studio LLC persiguen un arbitraje, la acción de arbitraje debe iniciarse y/o demandarse dentro de la prescripción (es decir, el plazo legal para presentar una reclamación) y dentro de cualquier plazo impuesto en virtud de las Reglas de la AAA para la reclamación pertinente.',
  'terms.paragraph_55':
    '(f) Autoridad del árbitro. Si se inicia el arbitraje, el árbitro decidirá los derechos y responsabilidades, en su caso, de usted y Startup Studio LLC, y la disputa no se consolidará con ningún otro asunto ni se unirá a ningún otro caso o partes. El árbitro estará facultado para otorgar mociones de resolución total o parcial de cualquier reclamación. El árbitro estará facultado para otorgar daños y perjuicios pecuniarios y otorgar cualquier recurso o alivio no monetario disponible para una persona en virtud de la ley aplicable, las Reglas de la AAA y los Términos. El árbitro emitirá un laudo escrito y una declaración de decisión en la que se describan las conclusiones y conclusiones esenciales en las que se basa el laudo, incluido el cálculo de los daños y perjuicios otorgados. El árbitro tiene la misma autoridad para otorgar medidas cautelares a título individual que tendría un juez de un tribunal. La adjudicación del árbitro es definitiva y vinculante para usted y Startup Studio LLC. g',
  'terms.paragraph_56':
    '(g) Renuncia al juicio con jurado. Las partes renuncian a sus derechos constitucionales y estatutarios a ir a la corte y a tener un juicio frente a un juez o jurado, eligiendo en su lugar que todas las reclamaciones y disputas serán resueltas por arbitraje bajo este Acuerdo de Arbitraje. Los procedimientos de arbitraje suelen ser más limitados, más eficientes y menos costosos que las normas aplicables en los tribunales y están sujetos a una revisión muy limitada por un tribunal. En el caso de que surja cualquier litigio entre usted y STARTUP STUDIO LLC en cualquier tribunal estatal o federal en una demanda para desocupar o hacer cumplir un laudo arbitral o de otro modo, USUY Y STARTUP STUDIO LLC RENUNCIAN TODOS LOS DERECHOS A UN TRIAL JURADO, en lugar de elegir que la disputa sea resuelta por un juez.',
  'terms.paragraph_57':
    '(h) Renuncia a acciones colectivas o consolidadas. TODAS LAS RECLAMACIONES Y DISPUTAS DENTRO DEL ÁMBITO DE ESTE ACUERDO DE ARBITRAJE DEBEN SER ARBITRADAS O LITIGADAS DE FORMA INDIVIDUAL Y NO POR CLASE, Y LAS RECLAMACIONES DE MÁS DE UN CLIENTE O USUARIO NO PUEDEN SER ARBITRADAS O LITIGADAS CONJUNTAMENTE O CONSOLIDADAS CON LAS DE CUALQUIER OTRO CLIENTE O USUARIO.',
  'terms.paragraph_58':
    '(i) Confidencialidad. Todos los aspectos del procedimiento de arbitraje, incluidos, entre otros, el laudo del árbitro y su cumplimiento, serán estrictamente confidenciales. Las partes acuerdan mantener la confidencialidad a menos que la ley lo exija otra cosa. El presente párrafo no impedirá que una parte presente ante un tribunal cualquier información necesaria para hacer cumplir el presente Acuerdo, ejecutar un laudo arbitral o solicitar medidas cautelares o equitativas.',
  'terms.paragraph_59':
    '(j) Divisibilidad. Si alguna parte o partes de este Acuerdo de Arbitraje se consideran inválidas o inaplicables por un tribunal de jurisdicción competente, entonces dicha parte o partes específicas no tendrán fuerza ni efecto y serán separadas y el resto del Acuerdo continuará en pleno vigor y efecto. k',
  'terms.paragraph_60':
    '(k) Derecho de renuncia. Cualquiera o todos los derechos y limitaciones establecidos en este Acuerdo pueden ser renunciados por la parte contra la cual se hace valer la reclamación. Dicha renuncia no renunciará ni aplicará ninguna otra parte de este Acuerdo. l',
  'terms.paragraph_61':
    '(l) Supervivencia del acuerdo. Este Acuerdo de Arbitraje sobrevivirá a la terminación de su relación con Startup Studio LLC. m',
  'terms.paragraph_62':
    '(m) Tribunal de Reclamaciones de Pequeña cuantía. No obstante lo anterior, usted o Startup Studio LLC pueden presentar una acción individual ante un tribunal de reclamos menores. n',
  'terms.paragraph_63':
    '(n) Socorro equitativo de emergencia. No obstante lo anterior, cualquiera de las partes puede solicitar un alivio equitativo de emergencia ante un tribunal estatal o federal a fin de mantener el statu quo pendiente del arbitraje. Una solicitud de medidas provisionales no se considerará una renuncia a ningún otro derecho u obligación en virtud del presente Acuerdo de Arbitraje. o',
  'terms.paragraph_64':
    '(o) Reclamaciones no sujetas a arbitraje. No obstante lo anterior, las reclamaciones de difamación, violación de la Ley de Fraude y Abuso Informático, así como la infracción o apropiación indebida de la patente, derechos de autor, marca comercial o secreto comercial de la otra parte no estarán sujetas a este acuerdo de arbitraje. p',
  'terms.paragraph_65':
    '(p) Tribunales. En cualquier circunstancia en que el Acuerdo anterior permita a las partes litigar ante los tribunales, las partes acuerdan someterse a la jurisdicción personal de los tribunales ubicados en el Condado de Salt Lake, Orange County Florida, para tal fin.',
  'terms.paragraph_66.1': '14.7 Ley aplicable. ',
  'terms.paragraph_66.2':
    'Los Términos y cualquier acción relacionada con ellos serán gobernados e interpretados por y bajo las leyes del Estado de Florida, de acuerdo con la Ley Federal de Arbitraje, sin dar efecto a ningún conflicto de principios legales que prevean la aplicación de la ley de otra jurisdicción . La Convención de las Naciones Unidas sobre los Contratos de Compraventa Internacional de Mercaderías no se aplica al presente Acuerdo.',
  'terms.paragraph_67.1': '14.8 Aviso. ',
  'terms.paragraph_67.2':
    'Cuando Startup Studio LLC requiera que proporcione una dirección de correo electrónico para acceder a ciertas características de Startup Studio LLC Properties, usted es responsable de proporcionar a Startup Studio LLC su dirección de correo electrónico más actual . En el caso de que la última dirección de correo electrónico que proporcionó a Startup Studio LLC no sea válida, o por cualquier razón no sea capaz de entregarle cualquier aviso requerido/permitido por los Términos, el envío por parte de Startup Studio LLC del correo electrónico que contenga dicho aviso constituirá, sin embargo, una notificación efectiva. Puede notificar a Startup Studio LLC en la siguiente dirección: Shuffield Lowman, 1000 Legion Place, Suite 1700, Orlando, FL 32801. Dicho aviso se considerará dado cuando sea recibido por Startup Studio LLC por carta entregada por un servicio de entrega nocturno reconocido a nivel nacional o por correo prepagado de primera clase en la dirección anterior.',
  'terms.paragraph_68.1': '14.9 Renuncia. ',
  'terms.paragraph_68.2':
    'Cualquier renuncia o incumplimiento por parte de Startup Studio LLC de hacer cumplir cualquier disposición de los Términos en una ocasión no se considerará una renuncia a cualquier otra disposición o de dicha disposición en cualquier otra ocasión .',
  'terms.paragraph_69.1': '14.10 Separabilidad. ',
  'terms.paragraph_69.2':
    'Si alguna disposición de los Términos es, por cualquier razón, considerada inválida o inaplicable, las demás disposiciones de los Términos seguirán siendo exigibles, y la disposición inválida o inaplicable se considerará modificada para que sea válida y exigible en la medida máxima permitida por la ley .',
  'terms.paragraph_70.1': '14.11 Contrato completo.',
  'terms.paragraph_70.2':
    'Los Términos son el acuerdo final, completo y exclusivo de las partes con respecto al tema del presente documento y sustituye y fusiona todas las discusiones previas entre las partes con respecto a dicho tema .',

  //Dashboard
  'dashboard.page_description': 'Bienvenido/a a tu tablero',
  'dashboard.my_community': 'Mi Comunidad',
  'dashboard.my_notifications': 'My Notifications',
  'dashboard.connection_requests': 'Connection Requests',
  'dashboard.level_1': 'Nivel 1',
  'dashboard.level_2': 'Nivel 2',
  'dashboard.level_3': 'Nivel 3',
  'dashboard.view_all_connections': 'VIEW ALL CONNECTIONS',
  'dashboard.recommended_connections': 'Recommended Connections',
  'dashboard.upcoming_events': 'PRÓXIMOS TRANSMISIONES EN VIVO',

  //Courses
  'course.month_1_title': 'EL EMPRENDIMIENTO Y TÚ',
  'course.month_2_title': 'ENTENDER LEARN TO START',
  'course.month_3_title': 'EL VIAJE DEL EMPRENDIMIENTO',
  'course.month_4_title': 'DESARROLLO (Parte 1)',
  'course.month_5_title': 'DESARROLLO (Parte 2)',
  'course.month_6_title': 'MARCA (Parte 1)',
  'course.month_7_title': 'MARCA (Parte 2)',
  'course.month_8_title': 'EMPEZAR (Parte 1)',
  'course.month_9_title': 'EMPEZAR (Parte 2)',
  'course.month_10_title': 'EMPEZAR (Parte 3)',
  'course.month_11_title': 'EMPEZAR (Parte 4)',
  'course.month_12_title': 'EMPEZAR (Parte 5)',

  //Levels
  'course.level_1': 'Nivel 1',
  'course.level_2': 'Nivel 2',
  'course.level_3': 'Nivel 3',
  'course.month_1_description': 'Entender el Emprendimiento y Entenderte a Ti',
  'course.month_2_description': 'Entender Learn to Start',
  'course.month_3_description': 'El Viaje del Emprendimiento',
  'course.month_4_description': 'La Etapa DESARROLLO (Parte 1)',
  'course.month_5_description': 'La Etapa DESARROLLO (Parte 2)',
  'course.month_6_description': 'La Etapa MARCA (Parte 1)',
  'course.month_7_description': 'La Etapa MARCA (Parte 2)',
  'course.month_8_description': 'EMPEZAR (Parte 1)',
  'course.month_9_description': 'EMPEZAR (Parte 2)',
  'course.month_10_description': 'EMPEZAR (Parte 3)',
  'course.month_11_description': 'EMPEZAR (Parte 4)',
  'course.month_12_description': 'EMPEZAR (Parte 5)',

  //Courses per month
  'navigation.course.welcome_to_your_course': 'Bienvenido(a) a tu curso',
  'navigation.course_month_1_lesson_1': 'Bienvenido/a al Nivel 1',
  'navigation.course_month_1_lesson_2': 'Los Mitos del Emprendimiento',
  'navigation.course_month_1_lesson_3': 'Definición de Emprendimiento',
  'navigation.course_month_1_lesson_4':
    'Razones por las que Fracasan las Startups',
  'navigation.course_month_1_lesson_5':
    'Habilidades y Características de Emprendedores/as Eficaces',
  'navigation.course_month_1_lesson_6':
    'La Gente Compra de Gente en la Que Cree y Confía',
  'navigation.course_month_1_lesson_7': 'Creación de Tu Marca Personal Primero',
  'navigation.course_month_1_lesson_8': 'Crea Tu Propuesta de Valor Individual',
  'navigation.course_month_1_lesson_9': 'Crea tu Video “Yo Soy”',

  'navigation.course_month_2_lesson_1': 'Bienvenido/a al Nivel 2',
  'navigation.course_month_2_lesson_2': 'El Viaje del Emprendimiento',
  'navigation.course_month_2_lesson_3':
    'Una Introducción al Modelo Learn to Start y los Cuatro Entornos',
  'navigation.course_month_2_lesson_4':
    'Las Habilidades Básicas y la Etapa APRENDER del Modelo Learn to Start',
  'navigation.course_month_2_lesson_5':
    'La Etapa DESARROLLAR del Modelo Learn to Start',
  'navigation.course_month_2_lesson_6':
    'La Etapa MARCA del Modelo Learn to Start',
  'navigation.course_month_2_lesson_7':
    'Entender EMPEZAR y las Medidas de Prueba del Modelo Learn to Start',
  'navigation.course_month_2_lesson_8':
    'Evalúa Tu Mentalidad y Conjunto de Habilidades',
  'navigation.course_month_2_lesson_9': 'El Proceso de Emprendimientop',
  'navigation.course_month_2_lesson10':
    'Crea Tu Propio Equipo y Encuentra a Tu Tutor/a',

  'navigation.course_month_3_lesson_1': 'Bienvenido/a al Nivel 3',
  'navigation.course_month_3_lesson_2': 'Empresa e Historia',
  'navigation.course_month_3_lesson_3':
    'El Proceso y Habilidad de Contar Historias',
  'navigation.course_month_3_lesson_4': 'Relevancia en el Mundo 4.0',
  'navigation.course_month_3_lesson_5': 'La Etapa APRENDER',
  'navigation.course_month_3_lesson_6': 'Problemas Que Valen La Pena Resolver',
  'navigation.course_month_3_lesson_7':
    'Identifica Un Problema Que Valga La Pena Resolver, Suposiciones y Tendencias del Mercado',
  'navigation.course_month_3_lesson_8': 'Conducir Un Análisis de la Industria',

  'navigation.course_month_4_lesson_1': 'Encontrar Una Solución',
  'navigation.course_month_4_lesson_2':
    'Creación de la Propuesta de Valor de Tu Startup',
  'navigation.course_month_4_lesson_3':
    'Crea la Propuesta de Valor de Tu Startup',
  'navigation.course_month_4_lesson_4':
    'Poner a Prueba La Propuesta de Valor de Tu Startup',
  'navigation.course_month_4_lesson_5':
    'Conducir una Validación de Mercado Para la Propuesta de Valor de Tu Startup',

  'navigation.course_month_5_lesson_1': 'Definir Innovación',
  'navigation.course_month_5_lesson_2': 'Entender la Innovación y Sus Enemigos',
  'navigation.course_month_5_lesson_3':
    'Las Cinco Habilidades de la Innovación',
  'navigation.course_month_5_lesson_4': 'La Etapa DESARROLLAR',
  'navigation.course_month_5_lesson_5':
    'Crear el Modelo Empresarial de Tu Startup',
  'navigation.course_month_5_lesson_6':
    'Escribe el Plan Conceptual de Tu Startup',

  'navigation.course_month_6_lesson_1': 'Definición de Marca',
  'navigation.course_month_6_lesson_2': 'Estrategias de Marca (Branding)',
  'navigation.course_month_6_lesson_3':
    'La Relación Entre la Historia y la Marca',
  'navigation.course_month_6_lesson_4': 'El Boceto de Tu Marca',
  'navigation.course_month_6_lesson_5': 'Crea el Boceto de Marca de Tu Startup',
  'navigation.course_month_6_lesson_6': 'Los Vehículos de la Marca',

  'navigation.course_month_6_lesson_7':
    'Crea los Vehículos de Marca de Tu Startup',
  'navigation.course_month_7_lesson_1':
    'Los Elementos Fundamentales de la Historia',
  'navigation.course_month_7_lesson_2':
    'Las Historias que Tu Empresa Puede Contar',
  'navigation.course_month_7_lesson_3':
    'Acoger a Tu Narrador de Historias Interno',
  'navigation.course_month_7_lesson_4': 'Conducir Grupos de Enfoque',
  'navigation.course_month_7_lesson_5': 'Tu Plan de Mercadeo',
  'navigation.course_month_7_lesson_6':
    'Crear el Plan de Mercadeo de Tu Startup',

  'navigation.course_month_8_lesson_1': 'Introducción al Plan de Negocio',
  'navigation.course_month_8_lesson_2':
    'El Mapa de Gestión de Desarrollo Empresarial',
  'navigation.course_month_8_lesson_3':
    'Crea el Mapa de Gestión de Desarrollo Empresarial de Tu Startup',
  'navigation.course_month_8_lesson_4': 'Las Medidas de Sustentabilidad',
  'navigation.course_month_8_lesson_5':
    'Prueba la Sustentabilidad de Tu Startup',
  'navigation.course_month_8_lesson_6':
    'El Proceso de Reintroducción del Emprendimiento',

  'navigation.course_month_9_lesson_1': 'Las Partes de un Plan de Negocios',
  'navigation.course_month_9_lesson_2':
    'Escribe el Plan de Negocios de Tu Startup',
  'navigation.course_month_9_lesson_3': 'Las Medidas de Prueba de la Eficacia',
  'navigation.course_month_9_lesson_4': 'Prueba la Eficacia de Tu Startup',

  'navigation.course_month_10_lesson_1': 'Una Introducción al Plan Financiero',
  'navigation.course_month_10_lesson_2':
    'Crea el Plan Financiero de Tu Startup',
  'navigation.course_month_10_lesson_3':
    'Las Medidas de Prueba de Rentabilidad',
  'navigation.course_month_10_lesson_4':
    'Prueba el Potencial de Rentabilidad de Tu Startup',

  'navigation.course_month_11_lesson_1': 'Generación de la Marca',
  'navigation.course_month_11_lesson_2': 'Necesidades de Un Plan de Negocios',
  'navigation.course_month_11_lesson_3':
    'Escribe el Informe Ejecutivo del Plan de Negocios de Tu Startup',
  'navigation.course_month_11_lesson_4':
    'Crea el Video de Introducción de la Marca de Tu Startup',

  'navigation.course_month_12_lesson_1': 'Venderte a Ti',
  'navigation.course_month_12_lesson_2': 'Promoverte a Ti Mismo/a',
  'navigation.course_month_12_lesson_3': 'Recordatorios Para el Futuro',
  'navigation.course_month_12_lesson_4': 'Crea Tu Video Final “Yo Soy”',
  'navigation.course_month_12_lesson_5': 'Crea el Discurso Final de Tu Startup',

  //Questions - Level 1
  'course.question_who_are_you_lv1_pg2': '¿Quién eres?',
  'course.guid_what_can_you_do_lv1_pg2': `Identifica y explica tu colección de pasiones, valores y habilidades que definen quién eres para el mundo profesional.`,
  'course.question_what_can_you_do_lv1_pg3': `¿Qué puedes hacer?`,
  'course.guid_who_are_you_lv1_pg3_pt1': `Identifica y explica tus habilidades.`,
  'course.guid_who_are_you_lv1_pg3_pt2': `Indica explícitamente las maneras en que puedes aportar valor en tu startup.`,
  'course.question_opportunities_lv1_pg4':
    '¿Cuáles son tus oportunidades de crecimiento?',
  'course.guid_opportunities_lv1_pg4_pt1': `Echa un vistazo a tu reflexión previa en la que respondiste la pregunta “qué puedo hacer” al identificar y explicar las habilidades que posees. ¿Qué espacio encuentras para el desarrollo de habilidades más fuertes o un conjunto de habilidades más diverso? Explica tus oportunidades de crecimiento.`,
  'course.guid_opportunities_lv1_pg4_pt2': `Habilidades Básicas: Resolución de problemas, creatividad, comunicación, colaboración, empoderamiento.`,
  'course.question_defining_value_lv1_pg5': '¿Qué define tu valor?',
  'course.question_power_source_lv1_pg5': '¿De dónde proviene tu poder?',
  'course.guid_lv1_pg5_pt1': `Para definir tu importancia, explica tu valor inherente.`,
  'course.guid_lv1_pg5_pt2': `Para identificar de dónde proviene tu poder, explica tu habilidad para efectuar cambios.`,
  'course.question_comunicate_relationships_lv1_pg6':
    '¿Qué relaciones debes tener con el fin de comunicar tu valor?',
  'course.guid_lv1_pg6_pt1': `¿En qué áreas tendría la tutoría el mayor impacto?`,
  'course.guid_lv1_pg6_pt2': `¿En qué áreas tendrían los/as socios/as el mayor impacto?`,
  'course.guid_lv1_pg6_pt3': `¿En qué áreas tendría una red el mayor impacto?`,
  'course.guid_lv1_pg6_pt4': `Después de responder las preguntas de reflexión, considera si ya tienes acceso a personas específicas que puedan desempeñar estos roles. Nómbralos/as, explica su valor y contáctalos/as.`,
  'course.question_comunicate_tools_lv1_pg7':
    '¿Qué herramientas necesitas para comunicar tu valor? ',
  'course.question_comunicate_skills_lv1_pg7':
    '¿Qué habilidades necesitas para comunicar tu valor? ',
  'course.guid_lv1_pg7_pt1': `Considera las diferentes plataformas que usarás para comunicar tu valor. ¿Qué herramientas necesitas a fin de utilizar estas plataformas eficazmente?`,
  'course.guid_lv1_pg7_pt2': `Las distintas plataformas requieren mensajes e historias diferentes en base a cómo funciona dicha plataforma. ¿Qué destrezas necesitas para comunicar mensajes e historias diferentes?`,
  'course.task1_individual_value_lv1_pg8':
    'Crea Tu Propuesta de Valor Individual',
  'course.task1_individual_value_desc_lv1_pg8':
    '¿Cuál es tu propuesta de valor?',
  'course.task1_individual_value_guid_lv1_pg8_pt1': `Encuentra tu propia articulación de tu propio valor. Se auténtico/a. Reiterarás continuamente sobre esta propuesta de valor a medida que adquieres experiencia, conocimientos y habilidades, pero ¿cuál es tu valor actualmente?`,
  'course.task1_individual_value_guid_lv1_pg8_pt2': `Una propuesta de valor es una promesa de valor que le comunicas a tu audiencia sobre ti mismo/a. En un enunciado, es la manera en que eliges cómo expresar y comunicar tu valor. Tú, como persona, necesitas una propuesta de valor, porque también tienes una audiencia que necesita una forma clara y fácil de entender por qué necesita tener una relación contigo.`,
  'course.task1_individual_value_guid_lv1': `A value proposition is the promise of value you are telling your audience about yourself. In a statement, it’s how you choose to express and communicate your value.`,
  'course.task1_i_am_title_lv1_pg9': 'Crea tu Video “Yo Soy”',
  'course.task1_i_am_desc_lv1_pg9': ' Crea tu Video “Yo Soy”',
  'course._p_task1_i_am_guid_lv1_pg9_pt1':
    'Tu tarea es crear un video de 90 segundos a 2 minutos que exprese tu propuesta de valor - un video que responda a la pregunta: ¿quién soy?',
  'course._p_task1_i_am_guid_lv1_pg9_pt2': `Considera lo siguiente:`,
  'course.task1_i_am_guid_lv1_pg9_pt3': `¿Cómo proporcionas suficiente contexto sin enfocar demasiado tiempo en tu pasado?`,
  'course.task1_i_am_guid_lv1_pg9_pt4': `¿Cómo crearás imágenes que hablen de tus habilidades en lugar de tener que exponer todo lo que puedes hacer?`,
  'course.task1_i_am_guid_lv1_pg9_pt5': `¿Cómo estableces el tono para tu marca personal de manera que puedas controlar la percepción que el público tiene de ti?`,
  'course._p_task1_i_am_guid_lv1_pg9_pt6': `Agrega este video a tu portafolio`,
  'course._b_task1_i_am_guid_lv1_pg9_pt6':
    'Sube tu video a YouTube o Vimeo y agrega el enlace de compartir de este video a tu portafolio en el área titulada, “Esto es Rendimiento.”',
  'course._b_task1_i_am_guid_lv1_pg9_pt7':
    'Agrega también tu enlace abajo para desbloquear el Mes 2 - Nivel 2.',
  'course.task1_i_am_guid_lv1_pg9_pt8':
    'Mira “El Poder de la Historia y el Fracaso” en Mi Orientación y Apoyo',

  //Questions - Level 2
  'course.question_risk_relationship_lv2_pg2':
    '¿Cuál es tu relación con el riesgo?',
  'course._p_guid_risk_relationship_lv2_pg2_pt1': `¿Dónde te colocas a ti mismo/a en el espectro de aversión al riesgo?`,
  'course._p_guid_risk_relationship_lv2_pg2_pt2': `Al hacer esta determinación, considera ejemplos reales de riesgos que has tomado en tu vida y cuáles fueron las condiciones para tomar esos riesgos.`,
  'course._p_guid_risk_relationship_lv2_pg2_pt3': `Si no tienes mucha experiencia tomando riesgos, considera lo que estás dispuesto/a a perder temporalmente, a fin de adquirir experiencia invalorable.`,
  'course.question_risk_relationship_lv2_pg3':
    '¿Cómo explicarías cada uno de los cuatro entornos?',
  'course._p_question_guid_relationship_lv2_pg3_pt1': `En tus propias palabras, explica cada uno de los Cuatro Entornos para que puedas internalizar mejor su valor e impacto.`,
  'course._p_question_guid_relationship_lv2_pg3_pt2': `Los Cuatro Entornos:`,
  'course.question_guid_relationship_lv2_pg3_pt3': `Historia`,
  'course.question_guid_relationship_lv2_pg3_pt4': `Relaciones`,
  'course.question_guid_relationship_lv2_pg3_pt5': `Fracaso`,
  'course.question_guid_relationship_lv2_pg3_pt6': `Tutoría`,
  'course.question_core_skills_lv2_pg4':
    '¿Cómo puedes conectar cada una de las 6 acciones de la etapa APRENDER a las capas anteriores del modelo: los 4 entornos dónde debes vivir y las habilidades básicas que debes desarrollar?',
  'course._p_guid_core_skills_lv2_pg4_pt1': `Traza líneas claras entre los 4 entornos y las 6 acciones de la etapa APRENDER, y las 5 habilidades básicas y las 6 acciones de la etapa APRENDER.`,
  'course._b_guid_core_skills_lv2_pg4_pt2': `Los Cuatro Entornos:`,
  'course.guid_core_skills_lv2_pg4_pt3': `- Historia`,
  'course.guid_core_skills_lv2_pg4_pt4': `- Relaciones`,
  'course.guid_core_skills_lv2_pg4_pt5': `- Fracaso`,
  'course.guid_core_skills_lv2_pg4_pt6': `- Tutoría`,
  'course._b_guid_core_skills_lv2_pg4_pt7': `Las Cinco Habilidades Básicas`,
  'course.guid_core_skills_lv2_pg4_pt8': `- Resolución de Problemas`,
  'course.guid_core_skills_lv2_pg4_pt9': `- Creatividad`,
  'course.guid_core_skills_lv2_pg4_pt10': `- Comunicación`,
  'course.guid_core_skills_lv2_pg4_pt11': `- Colaboración`,
  'course.guid_core_skills_lv2_pg4_pt12': `- Empoderamiento`,
  'course._b_guid_core_skills_lv2_pg4_pt13': `Las 6 Acciones de la Etapa APRENDER`,
  'course.guid_core_skills_lv2_pg4_pt14': `- Investigación `,
  'course.guid_core_skills_lv2_pg4_pt15': `- Encuestas`,
  'course.guid_core_skills_lv2_pg4_pt16': `- Análisis de Datos`,
  'course.guid_core_skills_lv2_pg4_pt17': `- Consideración de Alternativas`,
  'course.guid_core_skills_lv2_pg4_pt18': `- Creación de Tu Historia`,
  'course.guid_core_skills_lv2_pg4_pt19': `- Empatizar con Tu Mercado`,
  'course.question_LSM_stage_lv2_pg5':
    '¿Cómo puedes conectar cada una de las 6 acciones de la etapa APRENDER como base de las 6 acciones de la etapa DESARROLLAR?',
  'course._p_guid_LSM_stage_lv2_pg5_pt1': `Dibuja líneas claras que muestren causa y efecto entre las 6 acciones de la etapa APRENDER y las 6 acciones de la etapa DESARROLLAR.`,
  'course._b_guid_LSM_learn_stage_lv2_pg5': `Las 6 Acciones de la Etapa APRENDER`,
  'course._li1_guid_LSM_learn_lv2_pg5': `- Investigación `,
  'course._li2_guid_LSM_learn_lv2_pg5': `- Encuestas`,
  'course._li3_guid_LSM_learn_lv2_pg5': `- Análisis de Datos`,
  'course._li4_guid_LSM_learn_lv2_pg5': `- Consideración de Alternativas`,
  'course._li5_guid_LSM_learn_lv2_pg5': `- Creación de Tu Historia`,
  'course._li6_guid_LSM_learn_lv2_pg5': `- Empatizar con Tu Mercado`,
  'course._b_guid_LSM_develop_stage_lv2_pg5': `Las 6 Acciones de la Etapa DESARROLLAR`,
  'course._li1_guid_LSM_develop_lv2_pg5': `- Responder la pregunta de “por qué”`,
  'course._li2_guid_LSM_develop_lv2_pg5': `- Imaginar lo que puede ser `,
  'course._li3_guid_LSM_develop_lv2_pg5': `- Hacer predicciones sólidas para el futuro`,
  'course._li4_guid_LSM_develop_lv2_pg5': `- Detallar el “cómo”`,
  'course._li5_guid_LSM_develop_lv2_pg5': `- Ir de idea a ejecución`,
  'course._li6_guid_LSM_develop_lv2_pg5': `- Apunta al mercado correcto`,
  'course.question_brand_LSM_stage_lv2_pg6':
    '¿Cuáles son tus pasiones y valores?',
  'course._p_guid_brand_LSM_stage_lv2_pg6_pt1': `¿Sabes lo que te entusiasma e inspira? ¿Puedes articularlo con un lenguaje consistente al que has estado desarrollando en tu diario para tu marca personal?`,
  'course._p_guid_brand_LSM_stage_lv2_pg6_pt2': `¿Puedes identificar las convicciones que te guían? De nuevo, ¿puedes articularlas con un lenguaje que concuerde contigo y una audiencia futura?`,
  'course.question1_test_metrics_LSM_lv2_pg7':
    '¿Dónde te ves a ti mismo/a alineado/a con Learn to Start?',
  'course.question2_test_metrics_LSM_lv2_pg7':
    '¿Cómo aplica el modelo a tu viaje personal?',
  'course.question2_1_test_metrics_LSM_lv2_pg7':
    'What do you need to reconsider about it/them?',
  'course._p_guid_test_metrics_LSM_lv2_pg7': `Revisa las explicaciones de Gary sobre las diferentes capas y tus reflexiones individuales, a la vez que consideras estas dos importantes preguntas de reflexión.`,
  'course.task1_evaluate_mind_skills_lv2_pg8': `Completa una evaluación de ti mismo/a en términos del modelo Learn to Start, con el fin de obtener la transparencia que necesitas para evaluar tu crecimiento a lo largo de este programa.`,
  'course._b_task1_guid_env_evaluate_mind_skills_lv2_pg8': `Utilizando una escala del 1-5, donde 5 significa más fuerte y 1 significa más débil. ¿Cómo te evalúas a ti mismo/a en cada uno de los entornos y por qué?`,
  'course._li1_task1_guid_env_evaluate_mind_skills_lv2_pg8': `- Historia`,
  'course._li2_task1_guid_env_evaluate_mind_skills_lv2_pg8': `- Relaciones`,
  'course._li3_task1_guid_env_evaluate_mind_skills_lv2_pg8': `- Fracaso`,
  'course._li4_task1_guid_env_evaluate_mind_skills_lv2_pg8': `- Tutoría`,
  'course._b_task1_guid_skills_evaluate_mind_skills_lv2_pg8': ` Utilizando la misma escala del 1-5, ¿Cómo te evalúas a ti mismo/a en cada una de las habilidades básicas y por qué?`,
  'course._li1_task1_guid_skills_evaluate_mind_skills_lv2_pg8': `- Resolución de Problemas`,
  'course._li2_task1_guid_skills_evaluate_mind_skills_lv2_pg8': `- Creatividad`,
  'course._li3_task1_guid_skills_evaluate_mind_skills_lv2_pg8': `- Comunicación`,
  'course._li4_task1_guid_skills_evaluate_mind_skills_lv2_pg8': `- Colaboración`,
  'course._li5_task1_guid_skills_evaluate_mind_skills_lv2_pg8': `- Empoderamiento`,
  'course._p_task1_res_guid_evaluate_mind_skills_lv2_pg8': `Mira “La Felicidad Está Sobrestimada” en mi Orientación y Apoyo.`,
  'course.question_ent_process_lv2_pg9': `¿Cuáles son las habilidades y rasgos que te complemetarían mientras creas tu startup?`,
  'course._p_guid_ent_process_lv2_pg9': `Regresa a las entradas anteriores de tu diario en las que completaste tu primera tarea de este mes. Tu auto evaluación formará tu respuesta a esta pregunta de reflexión.`,
  'course.task2A_team_build_lv2_pg10': `Crea el equipo que necesitas ahora o el equipo que necesitarás en el futuro.`,
  'course._p_task2A_guid_team_build_lv2_pg10_pt1': `Considera a las personas dentro de tu grupo y la red que ya has creado en tu vida.`,
  'course._p_task2A_guid_team_build_lv2_pg10_pt2': `¿Cuáles son tus habilidades principales y qué tipo de experiencias te hacen falta?`,
  'course.task2B_team_build_lv2_pg10': `Identifica el/la tutor/a adecuado/a para ti. Comunícate con el/ella, establece un plan y ten tu primera reunión.`,
  'course._p_task2B_guid_team_build_lv2_pg10_pt1': `Considera a las personas en tu vida que tienen la experiencia adecuada en la industria, o comienza a investigar diferentes personas y utiliza los medios sociales para comunicarte con ellas.`,
  'course._p_task2B_guid_team_build_lv2_pg10_pt2': `Recurso: El Video de Apoyo y Orientación de Gary: “Las Relaciones Son la Clave”`,
  'course._p_task2B_guid_team_build_lv2_pg10_pt3':
    '¿A quién has identificado y por qué?',
  'course._p_task_guid_team_build_lv2_pg10': `Watch "Relationship is Key" in My Guidance & Encouragement`,

  //Questions - Level 3
  'course.question_business_story_lv3_pg2':
    '¿Cómo han captado tu atención algunas empresas a través de su historia?',
  'course._p_question_guid_business_story_lv3_pg2_pt1': `Utiliza tu perspectiva como un/a consumidor/a para completar estas reflexiones.`,
  'course._p_question_guid_business_story_lv3_pg2_pt2': `Identifica las empresas, las historias, y más importante aún, explica por qué estas historias resonaron contigo de una forma que lograron captar tu atención y atraer tu demanda.`,
  'course.question1_storytelling_lv3_pg3': `¿Cómo han influido tus comportamientos en las empresas del mercado?`,
  'course.question2_storytelling_lv3_pg3': `¿De qué manera has visto tu propia historia reflejada en las historias de empresas de las que eres un/a consumidor/a?`,
  'course._p_question_world40_lv3_pg4': `¿Cómo está representada la relevancia de una marca en su historia?`,
  'course.guid_world40_lv3_pg4': `Quiero que consideres las compras que estás pensando hacer en los próximos meses. Para estas compras,¿estás considerando probar una marca nueva en lugar de la que compras habitualmente? Si la respuesta es sí, identifica lo que hizo esta marca para que fuese relevante para ti como consumidor/a. Si la respuesta es no, identifica lo que están haciendo las marcas a las que eres leal para mantener su relevancia para ti como consumidor/a.`,
  'course.question1_learn_stage_lv3_pg5': `¿Cuáles son tus comportamientos actuales como consumidor/a? ¿Qué está influenciando tu comportamiento en cuanto a lo que demandas y cómo actúas para satisfacer esa demanda?`,
  'course.question2_learn_stage_lv3_pg5': `¿Te puedes ver a ti mismo/a como parte de una tendencia de consumo mayor? ¿Sabes si otros/as consumidores/as en tu demografía se están comportando de la misma manera? ¿Puedes explicar las tendencias en las que estás envuelto/a?`,
  'course.question3_learn_stage_lv3_pg5': `¿Cómo ves el cambio de tus comportamientos de consumo durante el próximo año o en los próximos cinco años? ¿Qué va a influenciar estos cambios de comportamiento?`,
  'course.guid_learn_stage_lv3_pg5': `Analiza tus propios comportamientos como un/a consumidor/a.`,
  'course.question1_problems_worth_solving_lv3_pg6': `Cuando tuviste que elegir entre competidores, ¿qué influyó en tu elección? `,
  'course.question2_problems_worth_solving_lv3_pg6': `Cuando consideras competidores en el mismo mercado, ¿cómo ha influído la transparencia en tus elecciones?`,
  'course.question3_problems_worth_solving_lv3_pg6': `¿Cómo ha influido  tu acceso a la información en las opciones entre competidores? `,
  'course.question4_problems_worth_solving_lv3_pg6': `¿Cómo luce la transparencia en el mercado?`,
  'course.guid_problems_worth_solving_lv3_pg6': `Analiza tus propios comportamientos como un/a consumidor/a.`,
  'course._p_task_watch_gaining_a_new_perspective':
    'Mira “Adoptar una Nueva Perspectiva” en mi Orientación y Apoyo.',
  'course.task1_identify_problems_worth_solving_lv3_pg7': `Identifica un problema que valga la pena resolver, suposiciones y tendencias del mercado realizando encuestas y entrevistas en el mercado.`,
  'course._p_task1_guid_uploads_identify_problems_worth_solving_lv3_pg7': `Subir:`,
  'course._b_task1_guid_uploads_identify_problems_worth_solving_lv3_pg7': `Subir Los Datos de Tu Encuesta y Entrevista`,
  'course._b_task1_1_guid_uploads_identify_problems_worth_solving_lv3_pg7': `Subir Un Desglose de Tu Problema, Suposiciones y Tendencias del Mercado`,
  'course._p_task1_guid_serve_identify_problems_worth_solving_lv3_pg7': `Identifica el mercado exacto al que quieres servir y el problema que quieres resolver para ese mercado.`,
  'course._p_task1_guid_assumptions_identify_problems_worth_solving_lv3_pg7': `Identifica las suposiciones que tienes para tu mercado y compruébalas para ver lo que es o no es cierto.`,
  'course._p_task1_guid_trends_identify_problems_worth_solving_lv3_pg7': `Investiga y observa las tendencias del mercado para ayudar a enfocarte en un problema real.`,
  'course._p_task2_conduct_analysis_lv3_pg8': `Conducir un análisis de la industria completando una investigación profunda.`,
  'course._p_ol_task2_uploads_conduct_analysis_lv3_pg8': `Subir:`,
  'course._b_task2_uploads_conduct_analysis_lv3_pg8':
    'Subir Tu Estudio y Datos Organizados',
  'course._b_task2_2_uploads_conduct_analysis_lv3_pg8':
    'Subir Tu Análisis de la Industria',
  'course._b_task2_guid_competitors_conduct_analysis_lv3_pg8': `Examina a tus competidores potenciales.`,
  'course._li1_task2_competitors_conduct_analysis_lv3_pg8': `- ¿Quiénes son?`,
  'course._li2_task2_competitors_conduct_analysis_lv3_pg8': `- ¿Cuáles son sus propuestas de valor?`,
  'course._li3_task2_competitors_conduct_analysis_lv3_pg8': `- ¿Qué porcentaje del mercado comparten? `,
  'course._li4_task2_competitors_conduct_analysis_lv3_pg8': `- ¿Cuáles son sus puntos fuertes, debilidades, oportunidades y desafíos?`,
  'course._b_task2_guid_position_conduct_analysis_lv3_pg8': `Evalúa tu posición potencial en el mercado.`,
  'course._li1_task2_guid_position_conduct_analysis_lv3_pg8': `- ¿Es un mercado aglomerado? `,
  'course._li2_task2_guid_position_conduct_analysis_lv3_pg8': `- ¿Tiene áreas que están siendo desatendidas?`,
  'course._p_ol_task2_technology_position_conduct_analysis_lv3_pg8': `- Considera cómo la tecnología afectará a tu industria en el futuro.`,
  'course._li1_task2_technology_position_conduct_analysis_lv3_pg8': `- ¿Qué tecnología se está utilizando actualmente?`,
  'course._li2_task2_technology_position_conduct_analysis_lv3_pg8': `- ¿En qué tipo de tecnología están invirtiendo tus competidores para el futuro?`,
  'course._b_task2_2_guid_position_conduct_analysis_lv3_pg8': `Examina el pronóstico económico para los próximos cinco a diez años en las regiones específicas en las que esperas comerciar.`,

  //Questions - Level 4
  'course.question1_finding_solutions_lv4_pg1': `¿Cómo debes reiterar al definir el problema que vale la pena resolver que identificaste?`,
  'course.question2_finding_solutions_lv4_pg1': `¿A quién, específicamente, le estás haciendo una promesa?`,
  'course._p_guid_finding_solutions_lv4_pg1': `Identifica los puntos débiles específicos de tus clientes/as y los beneficios específicos que están buscando.`,
  'course.question1_creating_proposition_lv4_pg2': `¿Qué suposiciones tuviste en la etapa APRENDER que el mercado en sí comprobó falsas? ¿Cómo pivotaste de los datos que recopilaste?`,
  'course.question2_creating_proposition_lv4_pg2': `¿Qué información sobre comportamientos, influencias y tendencias necesitas aún para solidificarte antes de continuar con la creación de tu PV (propuesta de valor)?`,
  'course.task_startup_proposition_lv4_pg3': `Crea la Propuesta de Valor de Tu Startup`,
  'course._p_ol_task_guid_startup_proposition_lv4_pg3': `3 Resultados:`,
  'course._li1_task_guid_outcomes_proposition_lv4_pg3': `- Satisface las necesidades de el /la consumidor/a que estás sirviendo.`,
  'course._li2_task_guid_outcomes_proposition_lv4_pg3': `- Remueve las molestias de el/la consumidor/a que estás sirviendo. `,
  'course._li3_task_guid_outcomes_proposition_lv4_pg3': `- Produce beneficios específicos al/la consumidor/a que estás sirviendo.`,
  'course._task_guid_test_proposition_lv4_pg3':
    'Prueba la propuesta de valor de tu startup.',
  'course._b_task_guid_test_proposition_lv4_pg3': `Comprueba la propuesta de valor de tu startup respondiendo a cada una de las siguientes preguntas:`,
  'course._li1_task_guid_test_proposition_lv4_pg3': `- ¿Es mi PV disruptiva?`,
  'course._li2_task_guid_test_proposition_lv4_pg3': `- ¿Es mi PV protegible?`,
  'course._li3_task_guid_test_proposition_lv4_pg3': `- ¿Tienen los/as consumidores/as que componen mi audiencia prevista una situación irrealizable?`,
  'course._li4_task_guid_test_proposition_lv4_pg3': `- ¿Cuán urgente es necesaria la solución que estoy creando en el mercado?`,
  'course._li5_task_guid_test_proposition_lv4_pg3': `- ¿Cuánta competencia existe para la solución que estoy creando y quién es mi competencia?`,
  'course._p_task_guid_resource_proposition_lv4_pg3': `Mira “De la Nada a la Demanda” en mi Orientación y Apoyo.`,
  'course.question1_testing_proposition_lv4_pg4': `¿Cómo se ve la primera versión de entrada al mercado de tu producto o servicio?`,
  'course.question2_testing_proposition_lv4_pg4': `¿Qué influencia ha tenido la investigación del mercado en la creación de tu propuesta de valor? `,
  'course.question3_testing_proposition_lv4_pg4': `¿Qué preguntas necesitas hacerle a tu mercado para que puedas adquirir una verdadera transparencia sobre cuán efectivamente tu propuesta de valor está satisfaciendo los tres resultados necesarios?`,
  'course.task2_market_validation_lv4_pg5': `Conducir una validación de mercado para tu propuesta de valor.`,
  'course._p_ol_task2_guid_uploads_market_validation_lv4_pg5': 'Subir:',
  'course._b_task2_guid_uploads_market_validation_lv4_pg5':
    'Subir Tu Versión Prototipo 1.0',
  'course._b_task2_1_guid_uploads_market_validation_lv4_pg5':
    'Subur La Agenda de Tu Grupo de Enfoque',
  'course._b_task2_2_guid_uploads_market_validation_lv4_pg5':
    'Subir Los Datos de Tu Grupo de Enfoque',
  'course._p_task2_guid_version1_market_validation_lv4_pg5': `- Crear una versión 1.0 de tu producto o servicio.`,
  'course._p_task2_guid_groups_market_validation_lv4_pg5': `- Organizar los grupos de enfoque para recolectar los comentarios de por lo menos 30 personas.`,
  'course._p_task2_guid_agenda_market_validation_lv4_pg5': `- Crear una agenda para la reunión que incluya cómo presentarás tu solución y qué preguntas vas a hacer para recolectar comentarios.`,
  'course._p_task2_guid_place_market_validation_lv4_pg5': `- Identifica el lugar o la forma de tecnología que usarás para conducir las reuniones y cualquier incentivo que planeas brindar por su participación.`,
  'course._p_task2_guid_host_market_validation_lv4_pg5': `- Conduce los grupos de enfoque y recolecta los datos.`,
  'course._p_task2_guid_resource_market_validation_lv4_pg5': `Mira “Relájate” en mi Orientación y Apoyo.`,

  //Questions - Level 5
  'course.question1_defining_innovation_lv5_pg1': `¿Cómo viste tus habilidades de resolver problemas y creatividad?¿Dónde encontraste un espacio para el crecimiento en estas áreas? ¿Cómo?`,
  'course.question2_defining_innovation_lv5_pg1': `¿Sientes que has aprovechado las diferentes experiencias por las que has pasado en este programa para ver un crecimiento en estas áreas? ¿Cómo?`,
  'course.question3_defining_innovation_lv5_pg1': `¿Has utilizado el proceso del pensamiento de diseño en tu viaje hasta ahora para introducir tu propuesta de valor al mercado?`,
  'course._p_defining_innovation_lv5_pg1': `Si fue así, ¿cómo implementaste el pensamiento de diseño y cuáles fueron los efectos? Si no fue así, ¿dónde te pudo haber ayudado el pensamiento de diseño en tus tareas anteriores?`,
  'course.question4_defining_innovation_lv5_pg1': `¿Cómo usarás el pensamiento de diseño este mes mientras creas tu modelo empresarial?`,
  'course.question1_understanding_innovation_lv5_pg2': `¿Has cambiado tu posición original en el espectro de riesgo? ¿Crees en ti lo suficiente como para sentirte capaz de tomar riesgos?`,
  'course.question2_understanding_innovation_lv5_pg2': `¿Te has sentido exitoso/a en la búsqueda de tu solución? ¿Te ha impedido ese éxito de cualquier manera a asumir nuevos retos o pivotar?`,
  'course.question1_five_skills_innovation_lv5_pg3': `¿Cuáles ideas has desechado que ahora reciclarás en cambio?`,
  'course.question2_five_skills_innovation_lv5_pg3': `¿Tú y tu equipo han creado las relaciones que permiten la innovación?`,
  'course.question3_five_skills_innovation_lv5_pg3': `¿Cómo respondes tú a estas preguntas comparado con las respuestas de los miembros de tu equipo?`,
  'course.question1_develop_stage_lv5_pg4': `¿Qué clase de startup eres? ¿Cómo lo sabes?`,
  'course._p_ul_question1_guid_startup_categories_lv5_pg4': `Tipos de Categorías de Startups`,
  'course._li1_question1_guid_startup_categories_lv5_pg4': `Mejorar algo que ya existe`,
  'course._li2_question1_guid_startup_categories_lv5_pg4': `Una solución completamente diferente para el mercado`,
  'course._li3_question1_guid_startup_categories_lv5_pg4': `Un concepto totalmente nuevo`,
  'course.task1_develop_bm_lv5_pg5': `Crear el Modelo Empresarial de Tu Startup`,
  'course._p_task1_research_guid_develop_bm_lv5_pg5': `Investiga modelos empresariales existentes`,
  'course._p_ul_task1_answer_guid_develop_bm_lv5_pg5': `Responde a las siguientes preguntas:`,
  'course.li1_task1_answer_guid_develop_bm_lv5_pg5': `¿Cómo ejecutarás tu propuesta de valor? `,
  'course.li2_task1_answer_guid_develop_bm_lv5_pg5': `¿Cuál es el modelo mediante el cual alcanzarás a tus clientes/as y generarás ingresos?`,
  'course._p_task1_create_guid_develop_bm_lv5_pg5': `- Crea el Modelo Empresarial usando un formato apropiado para tu startup.`,
  'course._p_ul_task1_uploads_guid_develop_bm_lv5_pg5': `Subir:`,
  'course._b_task1_uploads_guid_develop_bm_lv5_pg5': `Subir Tu Investigación Sobre Modelos Empresariales Existentes`,
  'course._b_task1_1_uploads_guid_develop_bm_lv5_pg5': `Subir El Modelo Empresarial de Tu Startup`,
  'course._p_task1_resource_guid_develop_bmc_lv5_pg5': `Recurso: Business Model Canvas`,
  'course._p_task1_resource_myth_guid_develop_bm_lv5_pg5': `Mira “El Mito de la Innovación” en mi Orientación y Apoyo`,
  'course.task2_concept_plan_lv5_pg6': `Escribe tu plan conceptual`,
  'course._p_ul_task2_guid_concept_plan_lv5_pg6': `Escribe un plan conceptual de 3 páginas para tu startup que incorpore cada una de las siguientes secciones:`,
  'course._li1_task2_guid_concept_plan_lv5_pg6': `- La necesidad del mercado de tu solución`,
  'course._li2_task2_guid_concept_plan_lv5_pg6': `- Una descripción de tu producto o servicio`,
  'course._li3_task2_guid_concept_plan_lv5_pg6': `- Una explicación de tu propuesta de valor`,
  'course._li4_task2_guid_concept_plan_lv5_pg6': `- Un análisis de tus competidores`,
  'course._li5_task2_guid_concept_plan_lv5_pg6': `- Una evaluación de riesgos`,
  'course._li6_task2_guid_concept_plan_lv5_pg6': `- Una explicación de tu modelo empresarial`,
  'course._p_ul_task2_uploads_concept_plan_lv5_pg6': `Subir:`,
  'course._b_task2_uploads_concept_plan_lv5_pg6': `Subir El Plan Conceptual de Tu Startup`,

  //Questions - level 6
  'course.question_who_are_you_lv6_pg1': '¿Quién eres?',
  'course.question_why_are_you_here_lv6_pg1': '¿Por qué estás aquí?',
  'course.question_ref_guidance_lv6_pg1':
    'Responde estas preguntas de reflexión desde el punto de vista de tu startup',
  'course.question_making_distinctive_offer_noisy_market_lv6_pg2':
    '¿Estás haciendo una oferta inconfundible en un mercado bullicioso?',
  'course.question_people_feel_about_you_lv6_pg2':
    '¿Cómo quieres que la gente se sienta con respecto a ti?',
  'course.question_developing_brand_instigate_value_creating_behaviors_lv6_pg2':
    '¿Cómo estás creando tu marca para promover comportamientos que crean valor?',
  'course.question_ref_guidance_lv6_pg2':
    'Responde estas preguntas de reflexión desde el punto de vista de tu startup',
  'course.question_telling_consistent_compelling_story_lv6_pg3':
    '¿Estás contando una historia consistente y convincente?',
  'course.question_your_story_improve_decision_quality_for_stakeholders_lv6_pg3':
    '¿Cómo mejora tu historia la calidad de decisión de cualquier parte interesada?',
  'course.guid_guidance_lv6_pg3':
    'Responde estas preguntas de reflexión desde el punto de vista de tu startup',
  'course.task1_create_startup_brand_charter_lv6_pg5': `Crea el Boceto de Marca de Tu Startup`,
  'course.question_associated_level_guidance_lv6_pg5':
    'Utiliza las preguntas asociadas con cada nivel del boceto marcado de la pirámide de la marca para crear el boceto de tu marca. Completarás los niveles de planificación y rendimiento más adelante en este programa.',
  'course.question_brand_charter_graphic_guidance_lv6_pg5':
    'Recurso: Gráfico de Bocetos de Marca ',
  'course.question_already_created_name_logo_lv6_pg6':
    '¿Ya has creado un nombre y un logotipo?',
  'course.question_need_reconsider_lv6_pg6':
    '¿Qué necesitas reconsiderar sobre él/ellos?',
  'course._p_task1_create_startup_brand_vehicles_lv6_pg7': `Crea los Vehículos de Marca de Tu Startup`,
  'course._p_task1_following_criteria_v1_brand_lv6_pg7': `Usa los siguientes criterios para crear la versión 1.0 del logotipo y nombre de tu marca.`,
  'course._b_task1_name_evaluation_criteria_lv6_pg7': `Criterios de Evaluación del Nombre:`,
  'course._li1_task1_lv6_pg7': `- ¿Apoya el nombre la estrategia organizacional?`,
  'course._li2_task1_lv6_pg7': `- ¿Personifica el boceto de la marca? `,
  'course._li3_task1_lv6_pg7': `- ¿Es el nombre diferenciador?`,
  'course._li4_task1_lv6_pg7': `- ¿Es el nombre culturalmente apropiado? `,
  'course._li5_task1_lv6_pg7': `- ¿Podemos proteger legalmente el nombre en nuestra vertical ? `,
  'course._li6_task1_lv6_pg7': `- ¿Nos ayudará el nombre a contar nuestra historia? `,
  'course._li7_task1_lv6_pg7': `- ¿Está avanzando el nombre hacia un patrimonio de marca positivo?`,
  'course._b_task2_logo_evaluation_lv6_pg7': `Criterios de Evaluación del Logotipo:`,
  'course._li1_task2_lv6_pg7': `- ¿Es el logotipo lo suficientemente flexible para permitirnos cambiar o expandir nuestro alcance y enfoque con el tiempo sin invalidar nuestra marca?`,
  'course._li2_task2_lv6_pg7': `- ¿Conecta la marca emocionalmente con nuestras audiencias objetivo claves?`,
  'course._li3_task2_lv6_pg7': `- ¿Se diferenciará la marca de la “competencia por la atención”?`,
  'course._li4_task2_lv6_pg7': `- ¿Incorpora la marca un símbolo inconfundible y fácilmente separable?`,
  'course._p_ul_task3_uploads_brand_logo_name_lv6_pg7': `Subir:`,
  'course._b_task3_uploads_name_evaluation_lv6_pg7':
    'Evaluación del Nombre de Tu Marca',
  'course._b_1_task3_uploads_brand_logo_evaluation_lv6_pg7':
    'Evaluación del Logotipo de Tu Marca',
  'course._b_2_task3_uploads_brand_name_and_logo_lv6_pg7':
    'Subir El Nombre de Tu Marca y Logotipo',

  //Questions - level 7
  'course.question_customer_relate_engage_lv7_pg1':
    '¿Con quién se relacionará y participará tu cliente/a?',
  'course.question_necessary_context_immerse_experience_lv7_pg1':
    '¿Posees el contexto necesario para sumergir a tu cliente/a en la experiencia de tu marca?',
  'course.question_convincing_explain_problem_solution_lv7_pg1':
    '¿Soy convincente cuando explico por qué mi problema vale la pena ser resuelto y por qué mi solución es la mejor? ',
  'course.question_story_resonate_customers_align_lv7_pg1':
    '¿Resuena mi historia con mis clientes/as y se adapta a sus historias? ',
  'course.question_language_brand_connect_customers_lv7_pg1':
    '¿Conecta el lenguaje de mi marca con mis clientes/as?',
  'course.question_brand_future_story_lv7_pg2':
    '¿Cuál es la historia futura de tu marca?',
  'course.question_brand_failure_story_lv7_pg2':
    '¿Cuál es la historia del fracaso de tu marca?',
  'course.question_brand_heart_story_lv7_pg2':
    '¿Cuál es la historia sentimental de tu marca?',
  'course.question_brand_authenticity_lv7_pg3':
    '¿De dónde proviene la autenticidad de tu marca?',
  'course._p_task1_condact_focus_groups_lv7_pg4': `Conducir Grupos de Enfoque`,
  'course._p_task1_feedback_30_people_v1_brand_lv7_pg4': `Debes obtener comentarios de por lo menos 30 personas.`,
  'course._p_task1_split_30_people_3_groups_of_10_lv7_pg4': `1. Divide a las 30 personas en al menos 3 grupos de 10.`,
  'course._p_ul_task1_create_agenda_groups_meetings_lv7_pg4': `2. Crea un horario para las reuniones de tu grupo de enfoque`,
  'course._li1_task1_lv7_pg4': `- Haz preguntas que te permitan recolectar datos sobre la conexión (o falta de) entre tu mercado y los vehículos de tu marca.`,
  'course._li2_task1_lv7_pg4': `- Haz preguntas que te permitan probar aún más tus suposiciones de tu mercado objetivo.`,
  'course._li3_task1_lv7_pg4': `3. Recopila los datos de cada grupo de enfoque en tu diario.`,
  'course._p_ul_task3_group_agenda_data_lv7_pg4': `Subir:`,
  'course._b_task3_uploads_name_evaluation_lv7_pg4':
    'Subir La Agenda de Tu Grupo de Enfoque',
  'course._b_1_task3_uploads_brand_logo_evaluation_lv7_pg4':
    'Subir Los Datos de Tu Grupo de Enfoque',
  'course.question_strategies_plan_lv7_pg5':
    'Para estrategias, ¿cuál es el plan?',
  'course.question_tactics_processes_lv7_pg5':
    'Para tácticas, ¿cuáles son nuestros procesos?',
  'course.question_accountability_live_brand_lv7_pg5':
    'Para responsabilidad, ¿cómo yo vivo la marca?',
  'course.question_delivery_measure_results_lv7_pg5':
    'Para ejecución, ¿cómo medimos los resultados?',
  'course.guid_return_brand_charter_lv7_pg5':
    'Regresa a la pirámide del boceto de marca que creaste en las entradas de tu diario del mes pasado. Tus preguntas de reflexión son la parte final de tu pirámide.',
  'course._p_task1_startup_marketing_plan_lv7_pg6': `Crear el Plan de Mercadeo de Tu Startup`,
  'course._p_task1_startup_marketing_plan_sections_and_subsections_lv7_pg6': `El Plan de Mercadeo de Tu Startup debe incluir las siguientes secciones y subsecciones:`,
  'course._p_ul_task1_market_analysis_lv7_pg6': `Análisis de mercado`,
  'course._li1_task1_lv7_pg6': `- Descripción del mercado`,
  'course._li2_task1_lv7_pg6': `- Hechos`,
  'course._li3_task1_lv7_pg6': `- Tamaño`,
  'course._li4_task1_lv7_pg6': `- Crecimiento`,
  'course._li5_task1_lv7_pg6': `- Demanda`,
  'course._li6_task1_lv7_pg6': `- Tendencias`,
  'course._li7_task1_lv7_pg6': `- Potencial de crecimiento`,
  'course._li8_task1_lv7_pg6': `- Oportunidades`,
  'course._li9_task1_lv7_pg6': `- Barreras de entrada`,
  'course._li10_task1_lv7_pg6': `- Reglamentos`,
  'course._li11_task1_lv7_pg6': `- Audiencia objetivo`,
  'course._li12_task1_lv7_pg6': `- Competencia`,
  'course._li13_task1_lv7_pg6': `- Segmento (nicho) `,
  'course._p_ul_task2_marketing_strategy_lv7_pg6': `Estrategia de Mercadeo`,
  'course._li1_task2_value_propositions_lv7_pg6': '- Propuesta de valor',
  'course._li2_task2_branding_lv7_pg6': '- Branding',
  'course._li3_task2_marketing_objective_lv7_pg6': '- Objetivo de mercadeo',
  'course._li4_task2_marketing_budget_lv7_pg6': '- Presupuesto de mercadeo',
  'course._li5_task2_swot_analysis_lv7_pg6':
    '- Análisis DAFO (debilidades, amenazas, fortalezas, oportunidades)',
  'course._li6_task2_marketing_mix_breakdown_lv7_pg6':
    '- Desglose de la mezcla de mercadeo (marketing mix)',
  'course._p_ul_task3_marketing_plan_lv7_pg6': `Subir:`,
  'course._b_task3_startup_marketing_plan_lv7_pg6':
    'Subir El Plan de Mercadeo de Tu Startup',

  //Questions - level 8
  'course.question_when_how_market_receive_product_lv8_pg1':
    '¿Sabes cuándo y cómo recibirá el mercado tu producto o servicio? ',
  'course.question_entrepreneur_experience_failure_lv8_pg1':
    '¿Estás preparado/a como emprendedor/a a experimentar el fracaso que conlleva el colocar el nombre de tu startup en el mercado?',
  'course.question_business_development_management_lv8_pg2':
    'Con El Mapa de Gestión del Desarrollo Empresarial, observa tu modelo empresarial y plan conceptual y reflexiona: ¿dónde necesitas hacer cambios y qué hace falta?',
  'course._p_task1_startup_bussines_development_lv8_pg3':
    'Crea el Mapa de Gestión de Desarrollo Empresarial de Tu Startup',
  'course._p_task1_need_template_lv8_pg3': `(Necesitaremos el formato para el MGDE (BDMM)`,
  'course._p_ul_task1_why_doing_this_lv8_pg3': `Paso 1: ¿Por qué estamos haciendo esto?`,
  'course._li1_task1_lv8_pg3': `Meta: El por qué existe la startup, el por qué los/as socios/as querrán involucrarse.`,
  'course._p_ul_task1_step2_look_future_lv8_pg3': `Paso 2: Mirar hacia el futuro `,
  'course._li1_task1_step2_goal_lv8_pg3':
    'Meta: ¿Dónde se ve a sí mismo/a en el futuro cada socio/a y dónde ven a la startup en el futuro?',
  'course._p_ul_task1_step3_predict_possibilities_lv8_pg3': `Paso 3: Predecir todas las posibilidades`,
  'course._li1_task1_step3_goal_lv8_pg3':
    'Meta: ¿Qué retos y complejidades enfrentamos y por qué fracasamos en alcanzar esta meta?',
  'course._p_ul_task1_step4_fill_details_lv8_pg3': `Paso 4: Rellena con los detalles`,
  'course._li1_task1_step4_goal_lv8_pg3':
    'Meta: ¿Quiénes son los/as participantes, los/as socios/as, las relaciones que debemos crear y cuáles son las actividades?',
  'course._p_ul_task1_step5_how_execute_lv8_pg3': `Paso 5: ¿Cómo ejecutaremos?`,
  'course._li1_task1_step5_goal_lv8_pg3':
    'Meta: Identifica los retos que enfrentamos en cada sección del mapa que hemos creado y redáctalos como preguntas “qué necesitamos hacer.”',
  'course._p_ul_task1_step6_redefine_lv8_pg3': `Paso 6: Redefine al usuario final`,
  'course._li1_task1_step6_goal_lv8_pg3':
    'Meta: redefine tu cliente/a objetivo y tu ruta de distribución',
  'course._p_ul_task1_step3_marketing_plan_lv8_pg3': `Subir:`,
  'course._b_task1_step3_startup_bussines_development_management_lv8_pg3':
    'Subir Tu Mapa de Gestión de Desarrollo Empresarial de Tu Startupp',
  'course.question_accumulated_information_lv8_pg4':
    '¿Has acumulado suficiente información y es tu producto/servicio suficientemente viable para permitir al mercado usarlo o al menos probarlo?',
  'course.question_excitement_belief_lv8_pg4':
    '¿Se siente real y genuino tu entusiasmo y convicción personal por tu producto/servicio y has explorado lo suficiente para saber que es una verdadera oportunidad con una posibilidad real de triunfar?',
  'course._p_task2_sustainability_startup_lv8_pg5': `Prueba la Sustentabilidad de Tu Startup`,
  'course._p_ul_task2_questions_to_consider_lv8_pg5': `Preguntas que considerar:`,
  'course._li1_task2_measure_sustainability_lv8_pg5': `- ¿Cómo medirás tu sustentabilidad?`,
  'course._li2_task2_prove_model_works_lv8_pg5': `- ¿Cómo comprobarás que tu modelo funciona?`,
  'course._li3_task2_prove_model_brings_consistent_lv8_pg5': `- ¿Cómo comprobarás que tu modelo tiene una demanda consistente?`,
  'course._li4_task2_prove_model_supported_lv8_pg5': `- ¿Cómo comprobarás que tu modelo está apoyado por una cultura bien elaborada?`,
  'course._li5_task2_specific_measurements_lv8_pg5': `- ¿Qué medidas específicas estás utilizando dentro de la categoría de sustentabilidad?`,
  'course._p_ul_task2_actions_to_take_lv8_pg5': `Acciones a Tomar:`,
  'course._li1_task2_articulate_measurment_lv8_pg5': '- Articula cada medida',
  'course._li2_task2_measurement_tools_lv8_pg5':
    '- Utiliza esas herramientas de medición para  verdaderamente comprobar la sustentabilidad de tu startup y recopila estos datos en tu diario.',
  'course._p_ul_task2_startup_sustainability_measurements_lv8_pg5': `Subir:`,
  'course._b_task2_startup_sustainability_measurements_lv8_pg5':
    'Las Medidas de Sustentabilidad de Tu Startup',
  'course._b_1_task2_startup_sustainability_data_lv8_pg5':
    'Subir Los Datos de Sustentabilidad de Tu Startup',
  'course._p_task2_recource_lv8_pg5': `Recurso: El Video de Apoyo y Orientación de Gary: “Tienes Que Tomar Riesgos”`,
  'course.question_market_want_need_solution_lv8_pg6':
    '¿Necesita o desea el mercado mi solución?',
  'course.question_solution_needed_emotional_level_lv8_pg6':
    '¿Es necesaria tu solución a nivel emocional y cómo vas a forzar un cambio y modificar el comportamiento del mercado? ',
  'course.question_market_identify_understand_solution_lv8_pg6':
    '¿Puede el mercado identificar y entender mi solución?',
  'course.question_story_telling_tryong_to_serve_lv8_pg6':
    '¿Se alinea la historia que estás contando con la historia de las personas que estás tratando servir?',
  'course.question_market_access_solution_lv8_pg6':
    '¿Puede el mercado acceder a mi solución con facilidad?',
  'course.question_market_physically_affordably_lv8_pg6':
    '¿Puede el mercado utilizar tu solución con cierta facilidad física y asequiblemente?',
  'course._p_recource_lv8_pg6': `Recurso: El Video de Apoyo y Orientación de Gary: “El Concepto del Tiempo”`,

  //Questions - level 9
  'course.question_who_is_audience_lv9_pg1': '¿Quién es tu audiencia?',
  'course.question_transparent_busines_lv9s_pg1':
    '¿Dónde te preocupas por ser transparente en tu plan de negocios ¿Por qué?',
  'course.question_lead_startup_success_lv9_pg1':
    '¿Cómo conducirá tu plan al éxito de tu startup?',
  'course._p_task1_startup_business_plan_lv9_pg2': `Escribe el Plan de Negocios de Tu Startup`,
  'course._p_ul_task2_include_following_sections_lv9_pg2': `Debes incluir las siguientes secciones en el plan de negocios de tu startup`,
  'course._li1_task2_general_company_description_lv9_pg2': `Descripción General de la Compañía: reseña de la compañía, misión, visión, cualidades, atributos centrales, retos. El plan de negocios debe tener aspectos positivos y ser razonable con respecto a los problemas y desafíos.`,
  'course._li2_task2_industry_analysis_lv9_pg2': `Análisis de la Industria: el estado en general de la industria a la que te vas a dirigir para mostrar tu experiencia e investigación.`,
  'course._li2_task2_product_service_overview_analysis_lv9_pg2': `Reseña del Producto o Servicio: un entendimiento claro y conciso de lo que has creado para el mercado. Descripción del producto o servicio, beneficios y características, necesidad del mercado.`,
  'course._li3_task2_marketing_plan_lv9_pg2': `Plan de Mercadeo: análisis del mercado (descripción del mercado, hechos, tamaño, crecimiento, demanda, tendencias, potencial de crecimiento, oportunidades, barreras de entrada, regulaciones, audiencia objetivo, competencia, segmento/nicho); estrategia de mercado (propuesta de valor, branding, objetivo de mercadeo, presupuesto de mercadeo, análisis DAFO, desglose de la mezcla de mercadeo).`,
  'course._li4_task2_operational_plan_lv9_pg2': `Plan Operacional: cómo intentas ejecutar y  operar como una entidad. Ejecución, cronología, ubicación, ambiente legal, personal, cualidades de gestión, asesores.`,
  'course._p_ul_task2_startup_business_plan_lv9_pg2': `Subir:`,
  'course._b_task2_startup_business_plan_lv9_pg2':
    'Subir El Plan de Negocios de Tu Startup',
  'course.question_level_organization_startup_lv9_pg3':
    '¿Qué nivel de organización tiene tu startup?',
  'course.question_delegated_responsibilities_lv9s_pg3':
    '¿Has delegado responsabilidades a todos los miembros de la startup?',
  'course.question_functioing_strict_budget_lv9_pg3':
    '¿Estás funcionando o planeas funcionar bajo un presupuesto estricto?',
  'course._p_task1_test_efficiency_lv9_pg4': `Prueba la Eficacia de Tu Startup`,
  'course._p_ul_task2_questions_to_consider_lv9_pg4': `Preguntas a Considerar:`,
  'course._li1_task2_measure_efficiency_lv9_pg4': `- ¿Cómo mides la eficacia?`,
  'course._li2_task2_prove_model_works_lv9_pg4': `- ¿Cómo probarás que tu modelo funciona?`,
  'course._li3_task2_prove_model_brings_organizational_infrastructure_lv9_pg4': `- ¿Cómo probarás que tu modelo aporta una infraestructura organizacional a tu startup?`,
  'course._li4_task2_specific_measurements_lv9_pg4': `- ¿Qué medidas específicas estás utilizando dentro de la categoría de eficacia?`,
  'course._p_ul_task2_actions_to_take_lv9_pg4': `Acciones a Tomar:`,
  'course._li1_task2_articulate_measurement_lv9_pg4': `- Articula cada medida`,
  'course._li1_task2_measurement_tools_lv9_pg4': `- Utiliza esas herramientas de medición para realmente probar la eficacia de tu startup y recopila estos datos en tu diario.`,
  'course._p_ul_task2_startup_efficiency_lv9_pg4': `Subir:`,
  'course._b_task2_startup_efficiency_measurements_lv9_pg4':
    'Las Medidas de Eficacia de Tu Startup',
  'course._b_1_task2_startup_efficiency_data_plan_lv9_pg4':
    'Subir Los Datos de Eficacia de Tu Startup',

  //Questions - level 10
  'course.question_understand_costs_lv10_pg1': '¿Entiendo los costos?',
  'course.question_understand_amount_lv10_pg1':
    '¿Entiendo la cantidad de capital que necesitaré para sustentar la startup a lo largo de cada etapa del plan?',
  'course.question_understand_phase_lv10_pg1':
    '¿Entiendo cuándo pasaremos de la etapa startup a la verdadera rentabilidad y sustentabilidad?',
  'course.question_understand_financial_lv10_pg1':
    '¿Realmente entiendo la gestión financiera de manera que garantice que las personas que quiero atraer tengan confianza en mis habilidades financieras?',
  'course._p_task1_create_your_startup_lv10_pg2': `Crea el Plan Financiero de Tu Startup`,
  'course._p_task2_research_examples_lv10_pg2': `Investiga ejemplos y modelos para que puedas hacer una elección informada de cómo presentas las proyecciones financieras de tu startup.`,
  'course._p_task2_schedule_meeting_lv10_pg2': `Concerta una reunión con tu tutor/a: haz preguntas y asegúrate de que comprendes claramente la información y consejos que estás recibiendo.`,
  'course._p_task2_personal_financial_plan_lv10_pg2': `Si actualmente no tienes un plan financiero personal, entonces debes crear uno primero. Como mínimo, debe incluir un presupuesto mensual, un plan de ahorros, un plan de inversión.`,
  'course._p_ul_task2_financial_lv10_pg2': `Subir:`,
  'course._b_task2_research_financial_lv10_pg2':
    'Subir Tu Investigación y Proyecciones Financieras',
  'course._b_task2_mentor_meeting_agenda_lv10_pg2':
    'Subir La Agenda de Tu Reunión Con Tu Tutor/a',
  'course._b_task2_personal_financial_plan_lv10_pg2':
    'Subir Tu Plan Financiero Personal',
  'course._b_task2_startup_financial_plan_lv10_pg2':
    'Subir El Plan Financiero de Tu Startup',
  'course.question_create_demand_lv10_pg3':
    '¿Puedo crear una demanda para mi producto o servicio en el mercado?',
  'course.question_consistent_satisfaction_lv10_pg3':
    '¿Está el producto/servicio probando unas medidas de satisfacción consistentes para el/la cliente/a?',
  'course.question_identifying_profitability_lv10_pg3':
    '¿Estoy identificando una rentabilidad real como resultado?',
  'course._p_task2_test_profitability_lv10_pg4': `Prueba el Potencial de Rentabilidad de Tu Startup`,
  'course._p_ul_task2_questions_to_consider_lv10_pg4': `Preguntas a Considerar:`,
  'course._li1_task2_measure_profitability_lv10_pg4': `- ¿Cómo medirás la rentabilidad?`,
  'course._li2_task2_prove_model_works_lv10_pg4': `- ¿Cómo probarás que tu modelo funciona?`,
  'course._li3_task2_prove_model_brings_organizational_consistant_demands_lv10_pg4': `- ¿Cómo probarás que tu modelo produce una demanda consistente?`,
  'course._li4_task2_specific_measurements_profitability_lv10_pg4': `- ¿Qué medidas específicas estás utilizando dentro de la categoría de rentabilidad?`,
  'course._p_ul_task2_actions_to_take_lv10_pg4': `Acciones a Tomar:`,
  'course._li1_task2_articulate_measurement_lv10_pg4': `- Articula cada medida`,
  'course._li1_task2_measurement_tools_lv10_pg4': `- Utiliza esas herramientas de medición para realmente probar la rentabilidad de tu startup y recopila estos datos en tu diario.`,
  'course._p_ul_task2_profitability_plan_lv10_pg4': `Subir:`,
  'course._b_task2_startup_profitability_lv10_pg4':
    'Las Medidas de Rentabilidad de Tu Startup',
  'course._b_task2_data_plan_lv10_pg4':
    'Subir Los Datos de Rentabilidad de Tu Startup',

  //Questions - level 11
  'course.question_brand_working_enough_lv11_pg1':
    '¿Está tu marca desempeñándose satisfactoriamente?',
  'course.question_iterate_brand_better_lv11_pg1':
    '¿Puedes reiterar sobre tu marca para contar una mejor historia?',
  'course.question_business_plan_ready_lv11_pg2':
    '¿Está tu plan de negocios listo para un informe ejecutivo?',
  'course.question_provide_clarity_ready_lv11_pg2':
    '¿Proporciona la claridad, análisis y transparencia necesarias para que tus lectores/as vean tu visión?',
  'course._p_task1_resource_chart_forming_legal_business_lv11_pg2': `Recurso: Jim Hunter’s Chart on Forming Legal Business Entities (Gráfico de Jim Hunter sobre La Formación de Entidades Empresariales legales)`,
  'course._p_task1_executive_summary_lv11_pg3': ` Escribe el Informe Ejecutivo del Plan de Negocios de Tu Startup`,
  'course._p_task1_reread_business_plan_lv11_pg3': `Relee todo tu plan de negocios.`,
  'course._p_ul_task1_consider_following_questions_lv11_pg3': `Considera las siguientes preguntas antes de comenzar a escribir:`,
  'course._li1_task1_clarity_to_reader_lv11_pg3': `- ¿Qué necesitas incluir para brindarle claridad a tu lector/a? `,
  'course._li2_task1_reader_see_your_vision_lv11_pg3': `- ¿Qué necesitas incluir para lograr que tu lector/a vea tu visión?`,
  'course._li3_task1_reader_understand_brand_lv11_pg3': `- ¿Qué necesitas incluir para tus lectores/as con el fin de entender si están alineados/as o no con tu marca?`,
  'course._p_task1_summarized_dynamic_one_page_lv11_pg3': `Una vez hayas hecho un resúmen dinámico de una página de tu trabajo, agrégalo a tu plan de negocios.`,
  'course._p_ul_task1_startup_evaluation_summary_lv11_pg3': `Subir:`,
  'course._b_task1_startup_evaluation_lv11_pg3':
    'Tu Evaluación del Plan de Negocios de Tu Startup',
  'course._b_task1_startup_executive_summary_lv11_pg3':
    'Subir El Informe Ejecutivo de Tu Startup',
  'course._p_task1_startup_video_lv11_pg4': `Crea el Video de Introducción de la Marca de Tu Startup`,
  'course._p_task1_promotional_video_lv11_pg4': `Este es un video promocional que introduce quién eres y qué estás ofreciendo al mercado. Este video debe hablar sobre el boceto de marca que creaste. Debe mostrar la esencia y personalidad de tu marca y su posicionamiento. No debe quedar duda de lo que es tu startup, lo que hace y sus valores.`,
  'course._p_task1_story_telling_lv11_pg4': `Primero, responde esta pregunta: ¿Qué historia estás contando? Luego, planea tu video.`,
  'course._p_task1_create_video_lv11_pg4': `Crea un video de 90 segundos a 2 minutos que introduzca tu marca al mundo.`,
  'course._p_task1_use_brand_charter_lv11_pg4': `Usa el boceto de tu marca para asegurarte de que cada elemento que escojas para tu video concuerda y permita que tu marca haga un impacto y funcione bien.`,
  'course._p_task1_use_elements_of_story_lv11_pg4': `Utiliza los elementos de la historia de las explicaciones de Bob, para arribar a un lugar de conexión con tu audiencia.`,
  'course._p_task1_upload_finalized_video_lv11_pg4': `Sube la versión final 1.0 del video de introducción de la marca de tu startup a tu portafolio digital.`,
  'course._b_task1_upload_finalized_video_lv11_pg4_add_link': `Also add your link to startup's brand introductory video below`,
  'course._p_put_the_link_to_introductory_video': `Put the link to your introductory video here`,
  'course._p_put_the_link_to_your_brand_introductory_video': `Coloca el enlace de tu video de presentación aquí...`,
  'course._p_ul_task1_startup_video_lv11_pg4': `Subir:`,
  'course._b_task1_startup_introductory_video_map_lv11_pg4':
    'SubirEl Esquema del Video de Introducción de la Marca de Tu Startup',
  'course._b_task1_startup_introductory_video_lv11_pg4':
    'El Video de Introducción de la Marca de Tu Startup',

  //Questions - level 12
  'course.question_inspire_people_lv12_pg1':
    '¿Inspiro a las personas con las que quiero asociarme?',
  'course.question_inspire_customers_lv12_pg1':
    '¿Inspiro a mis clientes/as cuando hablo con ellos/as?',
  'course.question_inspire_anyone_lv12_pg1':
    '¿Inspiro a cualquier persona con la que hablo sobre mi solución, independientemente de si son parte o no de mi comunidad empresarial?',
  'course.question_confident_communicating_in_person_lv12_pg2':
    '¿Cuán seguro/a estás de comunicar tu historia en persona?',
  'course.question_confident_communicating_digitally_lv12_pg2':
    '¿Cuán seguro/a estás de comunicar tu historia digitalmente?',
  'course.question_how_do_you_know_lv12_pg2': '¿Cómo lo sabes?',
  'course.question_changes_in_yourself_lv12_pg3':
    '¿Qué cambios observas en ti mismo/a?',
  'course.question_rate_yourself_lv12_pg3':
    '¿Considerarías tu habilidad para comprometerte en cualquiera de los 4 entornos o tu crecimiento en el área de las 5 habilidades principales menor, mayor o igual?',
  'course.question_capable_of_telling_story_lv12_pg3':
    '¿Eres capaz de contar tu historia de una forma que te aporte valor? ',
  'course.question_time_spent_lv12_pg3':
    '¿Cuánto tiempo has pasado creando relaciones? ',
  'course.question_embrace_failure_lv12_pg3':
    '¿Estás listo/a para acoger el fracaso y aprender del mismo?',
  'course.question_mentorship_independence_lv12_pg3':
    '¿Te has comprometido con la tutoría, pero has mantenido tu independencia con el fin de seguir tu pasión y tu visión?',
  'course._p_task1_create_final_video_lv12_pg4': `Crea Tu Video Final “Yo Soy”`,
  'course._p_ul_task1_rewatch_last_version_video_lv12_pg4': `Vuelve a ver la última versión del video “Yo Soy” y evalúa lo siguiente antes de hacer tu próxima versión:`,
  'course._p_li1_task1_answer_question_who_am_i_lv12_pg4': `- ¿Responde el video a la pregunta “Quién Soy”?`,
  'course._p_li2_task1_clear_in_meassging_value_lv12_pg4': `- ¿Estás claro/a en el mensaje de tu valor?`,
  'course._p_li3_task1_focused_today_past_lv12_pg4': `- ¿Estás centrado/a en el “Tú” de hoy o más enfocado/a en el “Tú” del pasado?`,
  'course._p_li4_task1_unique_brand_lv12_pg4': `- ¿Cuán única es tu marca personal y los elementos que elegiste para expresarla?`,
  'course._p_li5_task1_visual_components_lv12_pg4': `- ¿Comunican los componentes visuales la esencia de lo que eres?`,
  'course._p_li6_task1_growth_change_proposition_lv12_pg4': `- ¿Ha cambiado tu propuesta de valor personal tu crecimiento desde el segundo mes de este programa?`,
  'course._p_li7_task1_reflect_growth_lv12_pg4': `- ¿Cómo puedes reflejar el crecimiento que has experimentado?`,
  'course._p_task1_create_final_video_today_lv12_pg4':
    'Crea una versión final de tu video “Yo Soy” (que refleje quién eres hoy día) y agrégalo a tu portafolio digital.',
  'course._p_task1_add_link_to_unlock_month12_task2': `Also add your link to unlock Month 12 - Task 2`,
  'course._p_put_the_link_to_i_am_video': `Coloca el enlace de tu video Yo Soy aquí`,
  'course._p_task1_recource_lv12_pg4':
    'Recurso: I Am end slide (última diapositiva “Yo Soy”)',
  'course._p_ul_task1_i_am_video_lv12_pg4': `Subir:`,
  'course._b_task1_evaluation_video_map_lv12_pg4':
    'Evaluación de Tu Video Yo Soy',
  'course._li2_task1_i_am_video_lv12_pg4': 'Tu Video Yo Soy',
  'course._p_task1_startup_final_pitch_lv12_pg5': `Crea el Discurso Final de Tu Startup`,
  'course._p_task1_choose_audience_lv12_pg5': `Primero, debes escoger a tu audiencia: ¿te estás dirigiendo a tus tutores/as o a inversores/as potenciales?`,
  'course._p_ul_task1_audience_elements_lv12_pg5': `Con tu audiencia en mente, junta todos los elementos en un discurso dinámico.`,
  'course._p_li1_task1_use_video_lv12_pg5': `- ¿Utilizarás videos? `,
  'course._p_li2_task1_use_slides_lv12_pg5': `- ¿Utilizarás diapositivas?`,
  'course._p_li3_task1_tell_story_lv12_pg5': `- ¿Cómo contarás tu historia?`,
  'course._p_li4_task1_believe_you_solved_problem_lv12_pg5': `- ¿Cómo vas a lograr que crean que has resuelto un problema que vale la pena resolver y que tu solución producirá una verdadera demanda?`,
  'course._p_li5_present_prototype_lv12_pg5': `- ¿Cómo presentarás tu prototipo?`,
  'course._p_li6_task1_present_data_lv12_pg5': `- ¿Cómo presentarás tus datos? `,
  'course._p_li7_task1_your_ask_lv12_pg5': `- ¿Qué vas a querer?`,
  'course._p_ul_task1_startup_final_pitch_complete_lv12_pg5': `Subir:`,
  'course._p_put_the_link_startup_pitch':
    'Add link to final startup pitch video',
  'course._b_task1_final_pitch_preparation_map_lv12_pg5':
    'La Preparación del Discurso Final de Tu Startup',
  'course._b_task1_final_pitch_video_lv12_pg5':
    'El Video del Discurso Final de Tu Startup',
  'course._b_task1_complete_business_plan_lv12_pg5':
    'Subir El Plan de Negocios Completo de Tu Startup',

  'user.verify_email':
    'Por favor revisa tu correo electrónico para verificar tu cuenta (si no lo ves, por favor revisa en tu SPAM). Si no recibiste el correo electrónico, haz ',
  'user.click_to_verify_email': 'clic aquí ',
  'user.resend_verification_email': 'para que te lo reenvíen.',
  'user.sending_verification_email': 'Enviando correo electrónico...',
  'user.verification_email_sent':
    'Correo electrónico de verificación ha sido enviado. Por favor revisa tu buzón.',

  //Journal
  'journal.write_response': 'Escribe tu respuesta aquí',
  'journal.word': 'palabra',
  'journal.words': 'palabras',
  'journal.minimum_words': 'mínimo de palabras',
  'journal.put_iam_video_link': 'Coloca el enlace de tu video Yo Soy aquí',
  'journal.put_brand_introductory_video_link':
    'Coloca el enlace de tu video de presentación aquí...',
  'journal.view_document': 'Mira el Documento',
  'journal.files_uploaded_successfully': 'Tus archivo se han subido con éxito',
  'journal.prev_lesson': `Ve a La Lección Anterior`,
  'journal.go_to_next_lesson': 've a la próxima lección',
  'journal.download_pdf': 'Baja el PDF',
  'journal.download_jpg': 'Baja el JPG',
  'journal.print_certificate': 'Print Certificate',
  'journal.get_your_certificate': 'Recibe tu certificado',
  'journal.view_image': 'Haz clic para ampliar',

  //Portfolio
  'portfolio.edit_page_Summary_first': 'Summary ',
  'portfolio.edit_page_Summary_second': '(links to your profile) ',
  'portfolio.page_description':
    'Comparte tu empoderamiento rendimiento y bienestar con la comunidad global',
  'portfolio.my_portfolio_edit':
    'Share your empowerment, wellness, and performance with the global community',
  'portfolio.preview': 'Vista preliminar',
  'portfolio.user_bio':
    'John Smith comenzó su carrera trabajando para varias compañías, aumentando sus destrezas en diseño UI. Mientras ampliaba sus habilidades, descubrió que quería tener un mayor impacto en su comunidad y comenzó a buscar oportunidades que le ayudarían a sentirse más empoderado, formar una empresa impactante y crear más bienestar al comprender cómo lograr una mayor repercusión en mundo a su alrededor. Tras descubrir el Programa de Emprendimiento Learn to Start, él supo que había encontrado exactamente lo que estaba buscando.',
  'portfolio.this_is': 'ESTO ES',
  'portfolio.empowerement': 'EMPODERAMIENTO',
  'portfolio.performance': 'RENDIMIENTO',
  'portfolio.wellness': 'BIENESTAR',
  'portfolio.empowerment_p1':
    'El conocerse a sí mismo/a es la clave para forjar seres humanos empoderados. Por eso es que en The Startup Studio, todo comienza con la historia. Una vez que las personas se encuentran a sí mismas, pueden comenzar a desarrollar su auténtico potencial y descubrir cómo contar su verdadera historia debidamente.',
  'portfolio.empowerment_p2':
    'Tu video “Yo soy” habla de los resultados del EMPODERAMIENTO que has experimentado al contar tu historia y descubrir tu potencial.',
  'portfolio.performance_p':
    'The Startup Studio te reta a responder esta pregunta: ¿Qué puedes hacer? Porque esa es la única medida de importancia para un empleador del mercado del siglo 21. En este programa, logras el resultado de RENDIMIENTO al identificar un problema que vale la pena resolver y al proponer una solución que se convierte en el elemento central de tu startup. A través del proceso, tu startup comenzará a elaborar una historia impactante acerca de tu solución excepcional y los beneficios que ofreces a la comunidad global en general.',
  'portfolio.wellness_p1':
    'La gente está en el centro de este programa. Sin importar la edad, nuestro propósito es crear personas que conocen su historia y viven en ella de manera extraordinaria. A este resultado lo llamamos "BIENESTAR".',
  'portfolio.wellness_p2':
    'A medida que te comprendes a ti mismo/a y cómo encajas en el mundo, entras en un estado de bienestar que te empodera a hacer cosas increíbles.',
  'portfolio.wellness_p3':
    'La presentación que creas para tu startup comprueba exactamente lo que puedes hacer en el mundo. Es una manera increíble de demostrar el valor que tu historia y la de tu startup aportan al mercado.',
  'portfolio.publish_portfolio': 'Publicar Portafolio',
  'portfolio.set_up_my_portfolio': 'Configurar mi portafolio',
  'portfolio.instructions': 'Instrucciones:',
  'portfolio.edit_portfolio_txt1':
    'Crea tu portafolio ingresando la información abajo. Si necesitas actualizar tu nombre, profesión, biografía, fotografía o enlaces a redes sociales, por favor visita',
  'portfolio.visit_profile': 'Perfil.',
  'portfolio.edit_portfolio_txt1_1': 'página de',
  'portfolio.edit_portfolio_txt2':
    'A medida que progresas por el curso, se te proveerá información e instrucciones adicionales sobre cómo crear los diferentes elementos.',
  'portfolio.edit_portfolio_txt3':
    'Una vez completados todos los elementos, podrás publicar tu portafolio, haciéndolo público y dándote la habilidad de compartirlo con otros fuera del curso.',
  'portfolio.learn_to_start': 'MI PORTAFOLIO LEARN TO START',
  'portfolio.choose_your_header_color': 'Elije el color de tu encabezado',
  'portfolio.i_am_video_link': 'Pega el enlace al video Yo Soy aquí...',
  'portfolio.i_am_video_description': 'Presenta tu video Yo Soy aquí...',
  'portfolio.promo_video_link':
    'Pega el enlace al video Introductorio de la Marca aquí...',
  'portfolio.promo_video_description':
    'Presenta tu video Introductorio de la Marca aquí...',
  'portfolio.article_link': 'Navega para subir el PDF de la presentación...',
  'portfolio.article_description':
    'Presenta tu Discurso de Presentación aquí...',
  'portfolio.save_changes': 'Guardar Cambios',
  'portfolio.edit_who_am_i': 'EDIT WHO AM I?',
  'portfolio.edit_modal_first_paragrf':
    '(Edit your name, occupation, and social media',
  'portfolio.edit_modal_second_paragrf': 'in your account settings.',
  'portfolio.publish_checkbox':
    'Publish your portfolio to share your story with the global community.',
  'portfolio.edit_top_skills': 'EDIT TOP SKILLS',
  'portfolio.skills_title': 'TOP SKILLS',
  'portfolio.add_personal_skill_error': 'You have alredy this skill',
  'portfolio.add_personal_skill_success': 'Skill saved successfully',
  'portfolio.Suggested_Skills_Based_on_My_Profile':
    'Suggested Skills Based on My Profile',
  'portfolio.Add_Skills': 'Add Skills',
  'portfolio.Type_skill': 'Type skill (Example: coaching)',
  'portfolio.LICENSES_CERTIFICATIONS': 'LICENSES & CERTIFICATIONS',
  'portfolio.upload_new_license_image': 'Upload Image',
  'portfolio.add_new_Certified': 'Name (Example: Certified Interior Designer)',
  'portfolio.add_new_Certified_Issuing_Authority':
    'Issuing Authority (Example: Council for Interior Design Accreditation (CIDA))',
  'portfolio.add_new_Certified_Date_Issued': 'Date Issued',
  'portfolio.add_new_Certified_Credential ID': 'Credential ID',
  'portfolio.add_new_Certified_Credential URL': 'Credential URL',
  'portfolio.Add_Licenses_or_Certifications': 'Add Licenses or Certifications',
  'portfolio.all_field_needed': 'All fields are needed',
  'portfolio.EDIT_LICENSES_CERTIFICATIONS': 'EDIT LICENSES & CERTIFICATIONS',
  'portfolio.file_type':
    'File Types: .png or .jpg only Dimensions: 150 x 150px Size: 500KB max.',
  'portfolio.remove_license': 'Remove Licenses or Certifications',
  'portfolio.add_new_certificate': 'Click here to add new certificate',
  'portfolio.Publish.My.Portfolio': 'Publish My Portfolio',

  // Beyond Your Course
  'beyond_your_course.page_description':
    'Encouragement and guidance to help you on your journey.',
  'beyond_your_course.encouragement_videos': 'Videos de Apoyo',
  'beyond_your_course.master_classes': 'Clases Magistrales',
  'beyond_your_course.encouragement': 'Apoyo',
  'beyond_your_course.encouragement_description':
    'Videos del fundador y CEO de The Startup Studio para ayudarte en tu viaje.',
  'beyond_your_course.master_classes_description':
    'Orientación continua por parte de emprendedores y expertos para ayudarte en tu viaje.',

  'video.from_nothing_to_demand': 'De la Nada a la Demanda',
  'video.understanding_the_reality_of_execution':
    'Entender la realidad de la ejecución',
  'video.taking_a_new_perspective': 'Adoptar una Nueva Perspectiva',
  'video.understanding_a_broader_perspective_as_you_look_at_who_you_are_in_the_world':
    'Concebir una perspectiva más amplia cuando observas quién eres en el mundo.',
  'video.give_yourself_a_break': 'Relájate',
  'video.understanding_the_power_of_time_and_patience':
    'Entender el poder del tiempo y la paciencia',
  'video.happiness_is_overrated': 'La Felicidad está Sobrevalorada',
  'video.understanding_the_difference_between_happiness_and_wellness':
    'Entender la diferencia entre felicidad y bienestar',
  'video.relationship_is_key': 'Las Relaciones son la Clave',
  'video.understand_that_it_all_begins_with_people':
    'Entender que todo comienza con la gente',
  'video.rethinking_time': 'El Concepto del Tiempo',
  'video.understanding_that_time_is_not_a_linear_concept':
    'Entender que el tiempo no es un concepto lineal',
  'video.the_myth_of_entrepreneurship': 'El Mito de la Innovación',
  'video.understanding_the_real_meaning_of_innovation_compared_to_ideation':
    'Entender el significado real de innovación comparado a la ideación',
  'video.the_power_of_story_and_failure':
    'El Poder de la Historia y el Fracaso',
  'video.understanding_that_you_must_be_seen_and_that_failure_is_the_key_to_success':
    'Entender que debes ser visto/a y que el fracaso es la clave del éxito',
  'video.understanding_risk': 'Tienes que Tomar Riesgos',
  'video.understanding_what_it_really_means_and_why_you_must_overcome_the_fear_of_it':
    'Entender lo que realmente significa y por qué debes superar el miedo al mismo',
  'video.becoming_a_fear_less_leader': 'Convertirse en un(una) Líder Sin Miedo',
  'video.becoming_a_fear_less_leader_entrepreneur': 'Cindy Schooler',
  'video.forms_of_legal_organization': 'Formas Legales de Organización',
  'video.forms_of_legal_organization_entrepreneur': 'Jim Hunter',
  'video.managing_financial_risk_in_entrepreneurship':
    'Administrar el Riesgo Financiero',
  'video.managing_financial_risk_in_entrepreneurship_entrepreneur': 'Joe Gitto',
  'video.building_saas_solutions_that_scale':
    'Desarrollar Soluciones Saas Que Amplíen',
  'video.building_saas_solutions_that_scale_entrepreneur': 'Ridvan Aliu',
  'video.creating_an_international_startup': 'Crear una Startup Internacional',
  'video.creating_an_international_startup_entrepreneur': 'Sander Eijkenduijn',
  'video.now_playing': 'Mostrándose Ahora:',
  'video.introduction_to_the_balance_sheet':
    ' Introduction to the Balance Sheet',
  'video.introduction_to_the_profit_and_loss_sheet':
    'Introduction to the Profit and Loss Sheet',
  'video.introduction_to_cash_flow_analysis':
    'Introduction to Cash Flow Analysis',

  //Startup live
  'startup_live.page_description': 'Bienvenido(a) a tu salón en vivo.',
  'startup_live.starting_in': 'Comenzando en',
  'startup_live.join_session': 'únete a la sesión',
  'startup_live.now_streaming': 'Now streaming',
  'startup_live.startup_archive': 'Archivo Startup Live',
  'startup_live.startup_archive_description':
    'Clases grabadas de tu salón de Startup En Vivo.',

  //My notes
  'my_notes.page_title': 'Mis notas',
  'my_notes.success_added': 'Your note has been saved successfully',
  'my_notes.page_description': 'Encuentra analiza y edita tus notas',
  'my_notes.search': 'Buscar notas...',
  'my_notes.sort_by_date_created': 'Ordenar según la fecha de creación',
  'my_notes.sort_by_date_edited': 'Ordenar según la fecha de revisión',
  'my_notes.not_started': 'NO EMPEZADO',
  'my_notes.sample_note': 'Nota de Ejemplo',
  'my_notes.edited': 'Editada:',
  'my_notes.created_in': 'Creado en:',
  'my_notes.created_month': 'MI CURSO: Mes ',
  'my_notes.edit_note': 'Editar nota',
  'my_notes.save_note': 'Guardar nota',
  'my_notes.back': 'Back',
  'my_notes.meeting_room_note': 'Nota de Mi Sala de Reuniones',
  'my_notes.leave_without_saving':
    '¿Estás seguro que quieres salir sin guardar?',
  'my_notes.go_back': '¡Ay, devuélvete!',
  'my_notes.leave_anyway': 'Sal de todos modos.',
  'my_notes.add_button': 'ADD NEW NOTE',
  'my_notes.notes_title': 'Note Title',
  'my_notes.notes_title_neddet': 'You must insert a title',
  'my_notes.notes_title_text': 'Notes Text needed',
  'my_notes.notes_modal_body': 'Write your note here ...',
  'my_notes.note_title_needed': 'Note title needed',
  'my_notes.note_description_needed': 'Note description needed',
  'my_notes.add_a_new_note': 'Add a new note',

  //My journals
  'my_journal.page_title': 'WELLNESS JOURNAL',
  'my_journal.page_description':
    'Wellness is planning, acting, and responding with self-awareness with the intent of becoming your best self.',
  'my_journal.not_started_journal': 'NO EMPEZADO',
  'my_journal.edit_journal': 'Editar diarios',
  'my_journal.save_journal': 'Guardar diarios',
  'my_journal.search_journals': 'Buscar diarios',
  'my_journal.created_page': 'Mi Diario:',
  'my_journal.mentorship_title': 'MY MENTORSHIP JOURNAL PAGE',
  'my_journal.mentorship_description':
    'The Learn to Start Model is a guide in the lifelong process of developing the answers to the only three questions that matter: Who am I? What can I do? How do I prove it? You will use this journal to guide your development to those answers.',

  //Journals
  'journals.category_1': 'PURPOSE',
  'journals.category_2': 'CONFIDENCE',
  'journals.category_3': 'RELATIONSHIPS',
  'journals.category_4': 'BALANCE',
  'journals.category_5': 'DEVELOPMENT',
  'journals.category_6': 'OPPORTUNITY',
  'journals.category_7': 'ENERGY',
  'journals.category_8': 'INFLUENCES',
  'journals.category_9': 'ASPIRATIONS',
  'journals.category_10': 'RESOURCES',
  'journals.purpose_text':
    'In the space below you can reflect on each question as often as you determine helpful. Start with an initial reflection at the beginning of your journey, and return at times of frustration, achievement, excitement, and insecurity. You can modify any of your reflections or add a new one at any time..',
  'journals.cat_1_question_1':
    'What is the reason you are doing what you’re doing?',
  'journals.cat_1_question_2': 'Why is this important to you?',
  'journals.cat_2_question_1': 'What is your level of confidence?',
  'journals.cat_2_question_2': 'What strengthens your confidence?',
  'journals.cat_2_question_3': 'What diminishes your confidence?',
  'journals.cat_3_question_1': 'How are you building new relationships?',
  'journals.cat_3_question_2':
    'How are you influenced by the judgment of others?',
  'journals.cat_3_question_3': 'How are you maintaining your relationships?',
  'journals.cat_3_question_4': 'How are you evaluating your relationships?',
  'journals.cat_4_question_1': 'What brings you happiness?',
  'journals.cat_4_question_2': 'Do your commitments conflict with each other?',
  'journals.cat_4_question_3':
    'Do you feel like you’re missing out on any aspect of your life?',
  'journals.cat_5_question_1':
    'How do you track your growth in different areas of your life?',
  'journals.cat_5_question_2':
    'How do you talk to yourself when you make mistakes and when you achieve your goals?',
  'journals.cat_5_question_3':
    'Are you engaging in any self-destructive behaviors?',
  'journals.cat_6_question_1':
    'Are you seeing opportunities or are you seeing obstacles?',
  'journals.cat_6_question_2':
    'How do you evaluate yourself as a problem-solver?',
  'journals.cat_7_question_1': 'Do you have consistent sleep patterns?',
  'journals.cat_7_question_2': 'Do you maintain a healthy/balanced diet?',
  'journals.cat_7_question_3': 'Are you physically active?',
  'journals.cat_8_question_1': 'Who currently has the power to influence you?',
  'journals.cat_8_question_2': 'What currently has the power to influence you?',
  'journals.cat_9_question_1':
    'What version of yourself are you striving to be?',
  'journals.cat_9_question_2':
    'What do you want your life to be like in the future?',
  'journals.cat_10_question_1':
    'Do you have what you need to do what you want to do?',
  'journals.cat_10_question_2':
    'Who and what do you rely on to support yourself and your endeavors?',
  'journals.cat_10_question_3': 'Who and what do you want to rely on?',
  'journals.started': 'Started:',
  'journals.add_new_journal': 'ADD NEW REFLECTION',

  //Profile
  'profile.cancel_subscription': 'Cancela Mi Suscripción',
  'profile.are_you_canceling_subscription':
    '¿Estás seguro(a) que quieres cancelar tu suscripción?',
  'profile.cancel_subscription_text':
    'Una vez hayas cancelado, perderás acceso a la plataforma y cualquier trabajo guardado.',
  'profile.keep_me_subscribed': 'No, mantén mi suscripción',
  'profile.cancel_my_subscription': 'Sí, cancela mi suscripción',
  'profile.unsubscribed':
    'Tu suscripción ha sido cancelada exitosamente. ¡Lamentamos que te vayas!',
  'profile.unsubscribed_text':
    'Puedes ingresar y suscribirte de nuevo en cualquier momento dentro de los próximos 90 días para continuar donde te quedaste. Después de 90 días, tu información será borrada y todo el progreso de tu curso se perderá.',
  'profile.incorrect_number':
    'Your number format is incorrect. It must start with + then number ',

  //Create account
  'create_account.page_title': 'Ingrese a su cuenta',
  'create_account.page_description':
    'La inscripción es exclusivamente para nuestros Socios de Escuelas, Universidades, Institutos y Organizaciones. Por favor ingresa abajo tu correo electrónico y código de comunidad exclusivo para continuar.',
  'create_account.input_name': 'Nombre Completo',
  'create_account.input_email': 'Tu Correo Electrónico Universitario',
  'create_account.input_password': 'Contraseña',
  'create_account.input_password_label':
    'Al crear una cuenta, estás aceptando nuestros Términos de Servicio y Política de Privacidad',
  'create_account.input_password_confirm': '"Confirma tu Contraseña"',
  'create_account.password_policy':
    '(Your password must contain at least 8 numbers and/or characters)',
  'create_account.input_community_code': ' Código Comunitario',
  'create_account.privacy_policy':
    'Al crear una cuenta, estás aceptando nuestros Términos de Servicio y Política de Privacidad',
  'create_account.security': 'La seguridad de tu información es importante.',
  'create_account.security_link': 'Averigua cómo te protegemos.',
  'create_account.already_registered': '¿Ya estás inscrito(a)?',
  'create_account.already_registered_link': 'Iniciar sesión',
  'create_account.certification_provides':
    'La Certificación Learn to Start provee acceso a todos nuestros participantes a una formidable comunidad de recursos. Haz clic en el logo de abajo para averiguar más.',
  'create_account.full_name_empty': 'Full name field is required',

  'my_account.page_title': 'MY ACCOUNT',
  'my_account.page_description': 'Access and edit your account information',
  'my_account.update_password': 'Update My Password',
  'my_account.email_address': 'Tu Correo Electrónico Universitario',
  'my_account.edit_my_bio': 'EDIT MY BIO',
  'my_account.first_last_name': 'First & Last Name',
  'my_account.position': 'Current Position (25 character limit)',
  'my_account.type_position_placeholder':
    'Type current position or role here (Example: Digital Video Producer)',
  'my_account.type_position': 'Type current role or position',
  'my_account.upload_new_image': 'Upload New Image',
  'my_account.edit_social_media': 'Edit Social Media Links',
  'my_account.edit_my_summary': 'Edit My Summary',
  'my_account.edit_contact_information': 'Edit Contact Information',
  'my_account.edit_contact_information_info':
    '**Mobile phone informaiton is only used for notification and will not be visible to others',
  'my_account.contact_information': 'Contact information',
  'my_account.summary_placeholder':
    'Type or paste your professional bio here. This will be public, so do not include anything that you aren’t comfortable sharing, please.',
  'my_account.original_password': 'Contraseña original',
  'my_account.new_password': 'Contraseña Nueva',
  'my_account.repeat_new_password': 'Repetir la contraseña nueva',
  'alert.my_account.password_change_success':
    'Your password has been changed successfully',
  'my_account.password_change_success':
    'Your password has been changed successfully',
  'my_account.success_change': 'Your data has been changed successfully',
  'alert.my_account.success_change': 'Your data has been changed successfully',
  'my_account.link_to_my_Portfolio_text': 'Link to My Portfolio. ',
  'my_account.link_to_my_Portfolio_span': ' (click to copy)',
  'my_account.share_my_profile': 'Share My Profile',
  'my_account.share_my_portfolio': 'Share portfolio',
  'my_account.profile_tags_title': 'Profile Tags',
  'my_account.profile_description':
    'You haven’t added any profile tags… yet! Click the box below to add some so people can find you more easily in My Community.',
  'my_account.edit_my_profile_tags': 'EDIT MY PROFILE TAGS',
  'my_account.edit_my_profile_tags_select': 'Select Your Profile Tags',
  'my_account.edit_my_profile_tags_select_up':
    'Select up to 5 tags to help us connect you to your community.',
  'my_account.edit_my_profile_tags_search_box':
    'Type tag (Examples: entrepreneur, computers)',
  'my_account.edit_my_profile_select_tags': 'Selected Profile Tags:',
  'my_account.add_my_personal_profile_tags': 'Add your personal tag ',
  'my_account.add_my_profile_tags': 'Type below your tag you want to add ',
  'my_account.add_my_profile_tags_more': 'More than 5 tags are not allowed',

  //Chat
  'chat.engage_with_comunity': 'ENVUÉLVETE CON TU COMUNIDAD VIRTUAL',
  'chat.there_are': 'Hay otros',
  'chat.other_participants':
    ' participantes en línea ahora mismo. Conéctate con ellos/as abajo.  Escribe tu mensaje.',

  //Events
  'event.to_be_announced': 'Se Anunciará Oportunamente',
  'event.welcome_to_LTS': 'Bienvenido a tu clase de Learn to Start en Vivo',
  'event.welcome_to_LTS_date_time': '06 DE JUNIO, 2021 | 4:30 PM EST',
  'event.welcome_to_LTS_date': '9 de abril, 2021',
  'event.understanding_LTS':
    'Entendiendo Learn to Start: Conoce al fundador y director ejecutivo de The Startup Studio, Gary Conroy',
  'event.understanding_LTS_date_time': '24 DE ABRIL, 2021 | 4:30 PM EST',
  'event.understanding_LTS_date': '24 de abril, 2021',
  'event.anastasia_hall': 'Anastasia Hall',
  'event.gary_conroy': 'Gary Conroy',
  'event.welcome_to_LTS_1':
    'Bienvenido a tu clase de Learn to Start en Vivo | 09 de Marzo, 2021',
  'event.welcome_to_LTS_2':
    'Bienvenido a tu clase de Learn to Start en Vivo | 06 de Junio, 2021',
  'event.understanding_LTS_1':
    'Entendiendo Learn to Start: Conoce al fundador y director ejecutivo de The Startup Studio, Gary Conroy | 24 de Marzo, 2021',

  //Modals
  'modal.contact_us': 'Contáctenos',
  'modal.contact_us_email': 'Tu correo electrónico',
  'modal.contact_us_message': 'Añade tu mensaje aquí...',
  'modal.contact_us_send_message': 'Enviar mensaje',
  'modal.Public_Portfolio_link_will_automatically_appear_here':
    '(Public Portfolio link will automatically appear here)',

  //Subscription Ended
  'subscription.subscription_ended': 'Your Subscription Has Ended!',
  'subscription.resubscribe': 'To continue your journey, please resubscribe.',
  'subscription.charge':
    'Each month your card will be charged $15. You may cancel at any time.',
  'subscription.trial_ended': 'Your Free Trial Has Ended!',
  'subscription.subscribe': 'To continue your journey, please subscribe.',

  //Connections
  'connection.accept_connection': 'ACCEPT REQUEST',
  'connection.ignore_connection': 'IGNORE REQUEST',
  'connection.block_user': 'BLOCK USER',
  'connection.request.successful':
    'Your connection request was sent succesfully',
  'connection.accept_request':
    'You have successfully accepted the connection request',
  'connection.page_title': 'My Connections',
  'connection.page_description': 'Your connections around the globe',
  'connection.my_requests': 'My Connection Requests',
  'connection.recommended_requests': 'Recommended Connections',
  'connection.no_requests':
    'You don’t have any connection requests yet! Get started by exploring your recommended connections below and requesting connections with others who have similar interests. Or you can search your community by name using the search box above.',
  'connection.no_connections':
    'You don’t have any connection requests yet! Get started by exploring your recommended connections above and requesting connections with others who have similar interests. Or you can search your community by name using the search box above.',

  'connection.search_community': 'SEARCH YOUR COMMUNITY',
  'connection.user_blocked':
    'This user has been blocked. You will no longer see connection requests or messages from this user.',
  'connection.request_ignored':
    'This request has been ignored. You will no longer see it in your Connection Requests; however, the user may send you another request later.',
  'connection.title': 'connections',
  //Messenger
  'messenger.search_message': 'SEARCH MESSAGES',

  //Alerts
  'alert.fill_password_fields': 'Por favor rellena los campos de la contraseña',
  'alert.incorrect_password': 'Su contraseña es incorrecta',
  'alert.password_min_8': 'La contraseña debe contener al menos 8 caracteres',
  'alert.password_confirm_password_do_not_match':
    '¡La contraseña y la confirmación de la contraseña no coinciden!',
  'alerts.valid_email':
    'Por favor ingresa una dirección de correo electrónico válida.',
  'alerts.contact_message': 'Please enter a message.',
  'alert.password_limit_exceededs':
    'Has superado el límite de intentos, por favor inténtalo más tarde.',
  'alert.password_conform_policy':
    'La contraseña debe tener al menos un número y caracteres en minúscula y mayúscula.',
  'alerts.something_went_wrong':
    'Algo salió mal, por favor inténtelo de nuevo!',
  'alert.my_account.password_change_success':
    'Your password has been changed successfully',
  'alert.my_account.success_change': 'Your data has been changed successfully',
  'alerts.subscribed': '¡Te has suscrito exitosamente!',
  'alerts.success_change': 'Your changes has been saved successfully',
  'sidebar.story_in_motion': 'STORY IN MOTION®',
  'storyInMotion.page_description':
    'A Learn to Start Institute Podcast - Conversations on Empowerment, Wellness and Performance.',
  'storyInMotion.page_week_podcast': 'THIS WEEK’S PODCAST',
  'storyInMotion.page_week_now_playing': 'NOW PLAYING:',
  'storyInMotion.page_also_avalible':
    'Also available on your favorite streaming platform',
  'storyInMotion.previous_podcast_episodes': 'Previous Podcast Episodes',
  'storyInMotion.favorite': 'Favorite Episodes',
  'storyInMotion.load_more': 'Load more...',
  'storyInMotion.guest_q&a_videos': 'Story in Motion Guest Q&As',
  // SAVED page
  'my_saved.MEDIA': 'MY SAVED MEDIA',
  'my_saved.MEDIA_des': 'Your favorite content in one place.',
  'my_saved.MEDIA_VIDEOS': 'VIDEOS',
  'my_saved.MEDIA_PODCASTS': 'PODCASTS',
  'my_saved.not_saved_PODCASTS': 'No saved podcast!',
  'my_saved.alredy_saved_PODCASTS': 'Podcast is alredy saved',
  'my_saved.alredy_saved_PODCASTS_note': 'Note podcast page',
  'foul_words.notice':
    'We have detected foul words in your writing, please revise your text before submitting. Your account can be suspended if foul words are used. Contact us if you have any questions!',
  'student_journals.student-lts_title': 'LEARN TO START JOURNAL',
  'student_journals.student-lts_description':
    'The Learn to Start Model is a guide in the lifelong process of developing the answers to the only three questions that matter: Who am I? What can I do? How do I prove it? You will use this journal to guide your development to those answers.',
  'student_journals.student-wellnes_title': 'WELLNESS JOURNAL',
  'student_journals.student-wellnes_description':
    'Wellness is planning, acting, and responding with self-awareness with the intent of becoming your best self. Use this journal to reflect on your wellness journey and treat it as a lifelong process.',
  'student_journals.student-personal-finance_title': 'PERSONAL FINANCE JOURNAL',
  'student_journals.student-personal-finance_description':
    'In order to be market-ready, you must be financially literate. This journal will help you gain the knowledge and skills you need to make informed financial decisions.',
  'student_journals.student-leadership_title': 'LEADERSHIP JOURNAL',
  'student_journals.student-leadership_description':
    'Leadership comes in many forms but the foundation is leading yourself first. Use this journal to inspire your development as a leader.'
}

export default EsLangs
