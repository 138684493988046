import appLocaleData from 'react-intl/locale-data/es'
import EsLangs from '../locales/es_ES'

let EsLang = {
  messages: {
    ...EsLangs
  },
  locale: 'es-ES',
  data: appLocaleData
}
export default EsLang
