export const FETCH_OCCUPATION_GROUPS_PENDING = 'FETCH_OCCUPATION_GROUPS_PENDING'
export const FETCH_OCCUPATION_GROUPS_FULFILLED =
  'FETCH_OCCUPATION_GROUPS_FULFILLED'
export const FETCH_OCCUPATION_GROUPS_REJECTED =
  'FETCH_OCCUPATION_GROUPS_REJECTED'

export const FETCH_OCCUPATION_GROUP_WITH_ID_PENDING =
  'FETCH_OCCUPATION_GROUP_WITH_ID_PENDING'
export const FETCH_OCCUPATION_GROUP_WITH_ID_FULFILLED =
  'FETCH_OCCUPATION_GROUP_WITH_ID_FULFILLED'
export const FETCH_OCCUPATION_GROUP_WITH_ID_REJECTED =
  'FETCH_OCCUPATION_GROUP_WITH_ID_REJECTED'

export const FETCH_OCCUPATION_JOBS_WITH_GROUP_ID_PENDING =
  'FETCH_OCCUPATION_JOBS_WITH_GROUP_ID_PENDING'
export const FETCH_OCCUPATION_JOBS_WITH_GROUP_ID_FULFILLED =
  'FETCH_OCCUPATION_JOBS_WITH_GROUP_ID_FULFILLED'
export const FETCH_OCCUPATION_JOBS_WITH_GROUP_ID_REJECTED =
  'FETCH_OCCUPATION_JOBS_WITH_GROUP_ID_REJECTED'

export const FETCH_OCCUPATION_JOB_WITH_ID_PENDING =
  'FETCH_OCCUPATION_JOB_WITH_ID_PENDING'
export const FETCH_OCCUPATION_JOB_WITH_ID_FULFILLED =
  'FETCH_OCCUPATION_JOB_WITH_ID_FULFILLED'
export const FETCH_OCCUPATION_JOB_WITH_ID_REJECTED =
  'FETCH_OCCUPATION_JOB_WITH_ID_REJECTED'

export const CLEANUP_PATHWAYS_STATE = 'CLEANUP_PATHWAYS_STATE'
