//loading
export const LOADING = 'LOADING'

//course watched videos
export const GET_VIDEOS_WATCHED = 'GET_VIDEOS_WATCHED'
export const GET_VIDEOS_WATCHED_ERROR = 'GET_VIDEOS_WATCHED_ERROR'

//notes
export const GET_ALL_NOTES = 'GET_ALL_NOTES'
export const GET_ALL_NOTES_ERROR = 'GET_ALL_NOTES_ERROR'
export const GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS'
export const GET_NOTE_ERROR = 'GET_NOTE_ERROR'
export const SAVE_NOTE_SUCCESS = 'SAVE_NOTE_SUCCESS'
export const SAVE_NOTE_ERROR = 'SAVE_NOTE_ERROR'
export const NOTE_REMOVED_SUCCESS = 'NOTE_REMOVED_SUCCESS'
