import React from 'react'
// import logoImage from '../../assets/images/LearntoStart-Diagram-3D.png'
import logoImage from '../../assets/images/LTS Model - Triangle - All.png'

const Footer = () => {
  return (
    <div className='sidebar-bottom'>
      <div style={{ position: 'relative' }}>
        <img src={logoImage} alt='logoimage' />
      </div>
    </div>
  )
}

export default Footer
