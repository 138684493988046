export const GET_PERIODS_START = "GET_PERIODS_START";
export const GET_PERIODS_SUCCESS = "GET_PERIODS_SUCCESS";
export const GET_PERIODS_ERROR = "GET_PERIODS_ERROR";
export const GET_EVENTS_START = "GET_EVENTS_START";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const POST_EVENT_START = "POST_EVENT_START";
export const POST_EVENT_SUCCESS = "POST_EVENT_SUCCESS";
export const POST_EVENT_ERROR = "POST_EVENT_ERROR";
export const EDIT_EVENT_START = "EDIT_EVENT_START";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_ERROR = "EDIT_EVENT_ERROR";

export const DELETE_EVENT_START = "DELETE_EVENT_START";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export const CHANGE_EVENT_DATE_START = "CHANGE_EVENT_DATE_START";
export const CHANGE_EVENT_DATE_SUCCESS = "CHANGE_EVENT_DATE_SUCCESS";
export const CHANGE_EVENT_DATE_ERROR = "CHANGE_EVENT_DATE_ERROR";
export const OPEN_TASK_MODAL = "OPEN_TASK_MODAL";
export const CLOSE_TASK_MODAL = "CLOSE_TASK_MODAL";

export const OPEN_CALENDAR_EVENT_MODAL = "OPEN_CALENDAR_EVENT_MODAL";
export const CLOSE_CALENDAR_EVENT_MODAL = "CLOSE_CALENDAR_EVENT_MODAL";

export const OPEN_CALENDAR_DELETE_EVENT_MODAL =
  "OPEN_CALENDAR_DELETE_EVENT_MODAL";
export const CLOSE_CALENDAR_DELETE_EVENT_MODAL =
  "CLOSE_CALENDAR_DELETE_EVENT_MODAL";
