export const SET_SECTION_ONE_PENDING = 'SET_SECTION_ONE_PENDING'
export const SET_SECTION_ONE_FULFILLED = 'SET_SECTION_ONE_FULFILLED'
export const SET_SECTION_ONE_REJECTED = 'SET_SECTION_ONE_REJECTED'

export const SET_INSTRUCTOR_SECTION_ONE_PENDING =
  'SET_INSTRUCTOR_SECTION_ONE_PENDING'
export const SET_INSTRUCTOR_SECTION_ONE_FULFILLED =
  'SET_INSTRUCTOR_SECTION_ONE_FULFILLED'
export const SET_INSTRUCTOR_SECTION_ONE_REJECTED =
  'SET_INSTRUCTOR_SECTION_ONE_REJECTED'

export const SET_SECTION_TWO_PENDING = 'SET_SECTION_TWO_PENDING'
export const SET_SECTION_TWO_FULFILLED = 'SET_SECTION_TWO_FULFILLED'
export const SET_SECTION_TWO_REJECTED = 'SET_SECTION_TWO_REJECTED'

export const SET_INSTRUCTOR_SECTION_TWO_PENDING =
  'SET_INSTRUCTOR_SECTION_TWO_PENDING'
export const SET_INSTRUCTOR_SECTION_TWO_FULFILLED =
  'SET_INSTRUCTOR_SECTION_TWO_FULFILLED'
export const SET_INSTRUCTOR_SECTION_TWO_REJECTED =
  'SET_INSTRUCTOR_SECTION_TWO_REJECTED'

export const SET_CERTIFICATION_PENDING = 'SET_CERTIFICATION_PENDING'
export const SET_CERTIFICATION_FULFILLED = 'SET_CERTIFICATION_REJECTED'
export const SET_CERTIFICATION_REJECTED = 'SET_CERTIFICATION_REJECTED'

export const SET_INSTRUCTOR_CERTIFICATION_PENDING =
  'SET_INSTRUCTOR_CERTIFICATION_PENDING'
export const SET_INSTRUCTOR_CERTIFICATION_FULFILLED =
  'SET_INSTRUCTOR_CERTIFICATION_REJECTED'
export const SET_INSTRUCTOR_CERTIFICATION_REJECTED =
  'SET_INSTRUCTOR_CERTIFICATION_REJECTED'

export const SET_INSTRUCTOR_DEBRIEF_PENDING = 'SET_INSTRUCTOR_DEBRIEF_PENDING'
export const SET_INSTRUCTOR_DEBRIEF_FULFILLED =
  'SET_INSTRUCTOR_DEBRIEF_FULFILLED'
export const SET_INSTRUCTOR_DEBRIEF_REJECTED = 'SET_INSTRUCTOR_DEBRIEF_REJECTED'

export const SET_INSTRUCTOR_DEBRIEF_WITH_ID_PENDING =
  'SET_INSTRUCTOR_DEBRIEF_WITH_ID_PENDING'
export const SET_INSTRUCTOR_DEBRIEF_WITH_ID_FULFILLED =
  'SET_INSTRUCTOR_DEBRIEF_WITH_ID_FULFILLED'
export const SET_INSTRUCTOR_DEBRIEF_WITH_ID_REJECTED =
  'SET_INSTRUCTOR_DEBRIEF_WITH_ID_REJECTED'

export const SET_MASTERCLASS_PERCENTAGE_PENDING =
  'SET_MASTERCLASS_PERCENTAGE_PENDING'
export const SET_MASTERCLASS_PERCENTAGE_FULFILLED =
  'SET_MASTERCLASS_PERCENTAGE_FULFILLED'
export const SET_MASTERCLASS_PERCENTAGE_REJECTED =
  'SET_MASTERCLASS_PERCENTAGE_REJECTED'

export const SET_PODCAST_PERCENTAGE_PENDING = 'SET_PODCAST_PERCENTAGE_PENDING'
export const SET_PODCAST_PERCENTAGE_FULFILLED =
  'SET_PODCAST_PERCENTAGE_FULFILLED'
export const SET_PODCAST_PERCENTAGE_REJECTED = 'SET_PODCAST_PERCENTAGE_REJECTED'

export const SET_QA_PERCENTAGE_PENDING = 'SET_QA_PERCENTAGE_PENDING'
export const SET_QA_PERCENTAGE_FULFILLED = 'SET_QA_PERCENTAGE_FULFILLED'
export const SET_QA_PERCENTAGE_REJECTED = 'SET_QA_PERCENTAGE_REJECTED'

export const SET_INSTRUCTOR_MASTERCLASS_PERCENTAGE_PENDING =
  'SET_INSTRUCTOR_MASTERCLASS_PERCENTAGE_PENDING'
export const SET_INSTRUCTOR_MASTERCLASS_PERCENTAGE_FULFILLED =
  'SET_INSTRUCTOR_MASTERCLASS_PERCENTAGE_FULFILLED'
export const SET_INSTRUCTOR_MASTERCLASS_PERCENTAGE_REJECTED =
  'SET_INSTRUCTOR_MASTERCLASS_PERCENTAGE_REJECTED'

export const SET_INSTRUCTOR_PODCAST_PERCENTAGE_PENDING =
  'SET_INSTRUCTOR_PODCAST_PERCENTAGE_PENDING'
export const SET_INSTRUCTOR_PODCAST_PERCENTAGE_FULFILLED =
  'SET_INSTRUCTOR_PODCAST_PERCENTAGE_FULFILLED'
export const SET_INSTRUCTOR_PODCAST_PERCENTAGE_REJECTED =
  'SET_INSTRUCTOR_PODCAST_PERCENTAGE_REJECTED'

export const SET_INSTRUCTOR_QA_PERCENTAGE_PENDING =
  'SET_INSTRUCTOR_QA_PERCENTAGE_PENDING'
export const SET_INSTRUCTOR_QA_PERCENTAGE_FULFILLED =
  'SET_INSTRUCTOR_QA_PERCENTAGE_FULFILLED'
export const SET_INSTRUCTOR_QA_PERCENTAGE_REJECTED =
  'SET_QA_PERCENTAGE_REJECTED'
