// GET JOURNAL
export const JOURNAL_GET_SUCCESS = 'JOURNAL_GET_SUCCESS'
export const JOURNAL_GET_ERROR = 'JOURNAL_GET_ERROR'

// JOURNAL
export const JOURNAL_SAVE_SUCCESS = 'JOURNAL_SAVE_SUCCESS'
export const JOURNAL_SAVE_ERROR = 'JOURNAL_SAVE_ERROR'

//JOURNAL TRACK
export const JOURNAL_FINISHED_SUCCESS = 'JOURNAL_FINISHED_SUCCESS'
export const JOURNAL_FINISHED_ERROR = 'JOURNAL_FINISHED_ERROR'

//COURSE TRACK
export const SAVE_FINISHED_COURSE_SUCCESS = 'SAVE_FINISHED_COURSE_SUCCESS'

//FINISHED JOURNALS
export const GET_FINISHED_JOURNAL_SUCCESS = 'GET_FINISHED_JOURNAL_SUCCESS'
export const SAVE_FINISHED_JOURNAL_SUCCESS = 'SAVE_FINISHED_JOURNAL_SUCCESS'

//LOADING
export const LOADING_J = 'LOADING_J'

// SET JOURNAL TITLES

export const SET_JOURNAL_TITLES = 'SET_JOURNAL_TITLES'
