// eslint-disable-next-line
export default {
  FRIEND_REQUEST: {
    key: 'FRIEND_REQUEST',
    value: ' sent you a friend request'
  },
  RECOMMENDATION_REQUEST: {
    key: 'RECOMMENDATION_REQUEST',
    value: ' sent you a recommendation request'
  },
  ADDED_TO_PROJECT: {
    key: 'ADDED_TO_PROJECT',
    value: ' added you to a project'
  },
  INSTRUCTIONS_TICKET_REPLY: {
    key: 'INSTRUCTIONS_TICKET_REPLY',
    value: ' replied to your instruction question'
  },
  FEEDBACK_TICKET_REPLY: {
    key: 'FEEDBACK_TICKET_REPLY',
    value: ' replied to your feedback ticket'
  },
  FEEDBACK_RECEIVED: {
    key: 'FEEDBACK_RECEIVED',
    value: ' responded to your upload certification request'
  },
  PROFICIENT_SKILL: {
    key: 'PROFICIENT_SKILL',
    value: ' marked your skill as proficient'
  },
  DENIED_SKILL: {
    key: 'DENIED_SKILL',
    value: ' has denied your skill'
  },
  APPROVED_SKILL: {
    key: 'APPROVED_SKILL',
    value: ' marked your skill as approved'
  },
  APPROVAL_REQUEST: {
    key: 'APPROVAL_REQUEST',
    value: ' submitted their approval request'
  },
  REAPPROVAL_REQUEST: {
    key: 'REAPPROVAL_REQUEST',
    value: ' resubmited their uploads for approval'
  },
  CERTIFIED: {
    key: 'CERTIFIED',
    value: ' has certified you'
  },
  CERTIFICATION_REQUEST: {
    key: 'CERTIFICATION_REQUEST',
    value: ' submitted their upload for certification'
  },
  CERTIFICATION_SUBMIT: {
    key: 'CERTIFICATION_SUBMIT',
    value: ' submitted their certification'
  },
  IAMR_STUDENT_QUESTION: {
    key: 'IAMR_STUDENT_QUESTION',
    value: ' asked you a instruction quesion'
  },
  IAMR_STUDENT_QUESTION_UPDATE: {
    key: 'IAMR_STUDENT_QUESTION_UPDATE',
    value: ' updated questions'
  },
  IAMR_STUDENT_FEEDBACK_REPLY: {
    key: 'IAMR_STUDENT_FEEDBACK_REPLY',
    value: ' has replied to your feedback on their submission.'
  },
  IAMR_STUDENT_FEEDBACK_REPLY_UPDATE: {
    key: 'IAMR_STUDENT_FEEDBACK_REPLY_UPDATE',
    value: ' has updated feedback on their submission.'
  },
  DEFAULT_NOTIFICATION: {
    key: 'DEFAULT_NOTIFICATION',
    value: ' sent you a notifications'
  },
  INDUSTRY_PROBLEM: {
    key: 'INDUSTRY_PROBLEM',
    value: ' has submitted an industry problem'
  },
  INDUSTRY_PROBLEM_DENIED: {
    key: 'INDUSTRY_PROBLEM_DENIED',
    value: ' has denied your industry problem submission'
  },
  INDUSTRY_PROBLEM_APPROVED: {
    key: 'INDUSTRY_PROBLEM_APPROVED',
    value: ' has approved your industry problem submission'
  },
  IMMERSION_EXPERIENCE: {
    key: 'IMMERSION_EXPERIENCE',
    value: ' applied for immersion experience'
  },
  IMMERSION_EXPERIENCE_DENIED: {
    key: 'IMMERSION_EXPERIENCE_DENIED',
    value: ' has denied your experience application'
  },
  IMMERSION_EXPERIENCE_APPROVED: {
    key: 'IMMERSION_EXPERIENCE_APPROVED',
    value: ' has approved your experience application'
  },
  IAMR_SAVED_UPLOAD: {
    key: 'IAMR_SAVED_UPLOAD',
    value: ' saved an upload.'
  },
  IAMR_UPDATE_SAVED_UPLOAD: {
    key: 'IAMR_UPDATE_SAVED_UPLOAD',
    value: ' updated a saved upload.'
  }
}
