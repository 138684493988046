export const FETCH_LESSONS_PENDING = 'FETCH_LESSONS_PENDING'
export const FETCH_LESSONS_FULFILLED = 'FETCH_LESSONS_FULFILLED'
export const FETCH_LESSONS_REJECTED = 'FETCH_LESSONS_REJECTED'

export const EDIT_LESSONS_PENDING = 'EDIT_LESSONS_PENDING'
export const EDIT_LESSONS_FULFILLED = 'EDIT_LESSONS_FULFILLED'
export const EDIT_LESSONS_REJECTED = 'EDIT_LESSONS_REJECTED'

export const CREATE_LESSONS_PENDING = 'CREATE_LESSONS_PENDING'
export const CREATE_LESSONS_FULFILLED = 'CREATE_LESSONS_FULFILLED'
export const CREATE_LESSONS_REJECTED = 'CREATE_LESSONS_REJECTED'

export const DELETE_LESSONS_PENDING = 'DELETE_LESSONS_PENDING'
export const DELETE_LESSONS_FULFILLED = 'DELETE_LESSONS_FULFILLED'
export const DELETE_LESSONS_REJECTED = 'DELETE_LESSONS_REJECTED'
